import React from "react";
import AppWrapper from "components/AppWrapper";
import { FlexLoader } from "components/Loader";
import { debounce } from "lodash";
import { Box } from "@mui/material";
import { listLastGeolocations } from "queries/devices/lastGeolocations";
import DeviceMap from "./DeviceMap";

const MapMain: React.FC = () => {
  const ref = React.useRef<HTMLDivElement>(null);
  const [mapReady, setMapReady] = React.useState(false);

  const [firstDeviceLocation, setFirstDeviceLocation] = React.useState<{
    lat: number;
    lng: number;
  }>();
  const [firstDeviceLoading, setFirstDeviceLoading] = React.useState<boolean>(true);

  React.useEffect(() => {
    async function fetchFirstDeviceLocation() {
      const devices = await listLastGeolocations({ page: 1, page_size: 1, active: true });
      setFirstDeviceLoading(false);

      if (devices?.data?.length > 0) {
        const { latitude, longitude } = devices.data[0].geo_location;
        setFirstDeviceLocation({ lat: latitude, lng: longitude });
      }
    }

    fetchFirstDeviceLocation();
  }, []);

  React.useEffect(() => {
    const handleResize = debounce(() => {
      if (ref.current) {
        setMapReady(true);
      }
    }, 500);

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <AppWrapper mainSxProps={{ p: "16px", maxWidth: "unset", alignSelf: "unset" }} ref={ref}>
      <Box
        sx={{
          borderRadius: "16px",
          overflow: "hidden",
          height: {
            xs: "calc(100vh - 80px)", // Smaller height on mobile
            sm: "97vh", // Original height for larger screens
          },
        }}
      >
        {mapReady && !firstDeviceLoading ? (
          <DeviceMap firstDeviceLocation={firstDeviceLocation} />
        ) : (
          <FlexLoader />
        )}
      </Box>
    </AppWrapper>
  );
};

export default MapMain;
