import React from "react";
import { Card, Typography, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ROUTES from "routes";
import TypographyHighlited from "components/TypographyHighlited";
import { useAuth } from "context/AuthProvider";
import CheckIcon from '@mui/icons-material/Check';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { ReactComponent as TwoPestsIcon } from '../../../icons/two-pests.svg';

interface DeviceCardProps {
  smpID: string;
  uuid: string;
  pestName: string;
  unvalidatedImageCount: number;
  deviceStatus: string;
  validatorName?: string;
  isApprovalRequired?: boolean;
}

const ValidatorDeviceCard: React.FC<DeviceCardProps> = ({
  smpID,
  uuid,
  pestName,
  unvalidatedImageCount,
  deviceStatus,
  validatorName,
  isApprovalRequired,
}) => {
  const { isAdmin } = useAuth();

  const navigate = useNavigate();

  const handleCardClick = () => navigate(`${ROUTES.VALIDATOR_DEVICES}/${uuid}`);

  return (
    <Card
      sx={{
        padding: "16px",
        paddingTop: "8px",
        display: "flex",
        flexDirection: "column",
        height: "190px",
        boxShadow: 2,
        border: unvalidatedImageCount > 0 ? "1px solid #FFFFFF" : "none",
        boxSizing: "border-box",
        transition: "background-color 0.2s ease-in-out",
        borderRadius: "18px",
        position: "relative",
        overflow: "visible",
        backgroundColor: unvalidatedImageCount > 0 ? "rgba(255, 255, 255, 0.4)" : "rgba(39, 56, 44, 0.1)",
        "&:hover": {
          backgroundColor: unvalidatedImageCount > 0 ? "rgba(255, 255, 255, 0.9)" : "rgba(39, 56, 44, 0.2)",
        },
      }}
      onClick={handleCardClick}
    >
      <Box
        sx={{
          position: "absolute",
          top: -10,
          right: -10,
          backgroundColor: unvalidatedImageCount > 0 ? "#F4B71D" : "#27382C",
          borderRadius: "50%",
          width: 45,
          height: 45,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          zIndex: 1,
        }}
      >
        {unvalidatedImageCount > 0 ? (
          <Typography
            variant={unvalidatedImageCount > 999 ? "body1" : "h6"}
            sx={{ 
              fontWeight: "bold", 
              color: "#F1EEEA",
              fontSize: unvalidatedImageCount > 999 ? "0.9rem" : undefined
            }}
          >
            {unvalidatedImageCount}
          </Typography>
        ) : (
          <CheckIcon sx={{ color: "#F1EEEA" }} />
        )}
      </Box>

      <Box sx={{ display: "flex", flexGrow: 1 }}>
        <Box>
          <Typography 
            variant="body1" 
            sx={{ fontWeight: "bold", marginTop: 1, color: "#27382C", marginLeft: 0.5 }}
          >
            {smpID}
          </Typography>
          { validatorName && isAdmin && (
            <Box sx={{ display: "flex", alignItems: "center", marginTop: 2, justifyContent: "flex-start", gap: 1 }}>
              <PersonOutlineIcon sx={{ color: "#27382C", width: 24, height: 24, flexShrink: 0 }} />
              <Typography variant="body2">
                {validatorName.toUpperCase()}
              </Typography>
            </Box>
          )}

          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start", gap: 1, marginTop: 1 }}>
            <TwoPestsIcon style={{ width: 24,flexShrink: 0 }} />
            <Typography variant="body2">{pestName}</Typography>
          </Box>
          {isApprovalRequired && isAdmin && (
            <Box sx={{ marginTop: 2 }}>
              <TypographyHighlited status="warning">APPROVE REQUIRED</TypographyHighlited>
            </Box>
          )}
        </Box>
      </Box>

      <Box sx={{ marginTop: "auto" }}>
        <TypographyHighlited
          status={deviceStatus === "Active" || deviceStatus === "Working" ? "ok" : "error"}
        >
          {deviceStatus}
        </TypographyHighlited>
      </Box>
    </Card>
  );
};

export default ValidatorDeviceCard;
