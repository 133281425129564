import React from "react";
import { Box, Button, TextField, MenuItem, Modal, Typography, Autocomplete, Alert } from "@mui/material";
import { Controller, FormProvider } from "react-hook-form";
import { USER_TYPE } from "queries/users";
import { AxiosError } from "axios";

interface EditUserModalProps {
    open: boolean;
    onClose: () => void;
    methods: any;
    onSubmit: (data: any) => void;
    corporations: any[];
    fetchNextPage: () => void;
    hasNextPage: boolean;
    isFetchingNextPage: boolean;
    updateError: AxiosError | null;
}

const EditUserModal = ({
    open,
    onClose,
    methods,
    onSubmit,
    corporations,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    updateError
}: EditUserModalProps) => {
    const handleScroll = (event: React.SyntheticEvent) => {
        const list = event.currentTarget;
        if (
            list.scrollTop + list.clientHeight >= list.scrollHeight - 50 &&
            hasNextPage &&
            !isFetchingNextPage
        ) {
            fetchNextPage();
        }
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="edit-modal"
        >
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 8,
                borderRadius: 8,
                minWidth: 400,
                maxWidth: 600
            }}>
                <Typography variant="h6" sx={{ mb: 2 }}>Edit User</Typography>
                {updateError !== null && (
                    <Alert severity="error" sx={{ mb: 2 }}>
                        Hoops something went wrong.
                    </Alert>
                )}
                <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit(onSubmit)}>
                        <Box sx={{ width: "100%", display: "flex", flexDirection: "column", gap: 2 }}>
                            {/* Username */}
                            <Controller
                                name="name"
                                control={methods.control}
                                defaultValue=""
                                rules={{ required: "Username is required" }}
                                render={({ field, fieldState: { error } }) => (
                                    <TextField
                                        {...field}
                                        label="Username"
                                        error={!!error}
                                        helperText={error?.message}
                                    />
                                )}
                            />

                            {/* First Name */}
                            <Controller
                                name="firstName"
                                control={methods.control}
                                defaultValue=""
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="First Name"
                                    />
                                )}
                            />

                            {/* Last Name */}
                            <Controller
                                name="lastName"
                                control={methods.control}
                                defaultValue=""
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Last Name"
                                    />
                                )}
                            />

                            {/* Email */}
                            <Controller
                                name="email"
                                control={methods.control}
                                defaultValue=""
                                rules={{
                                    required: "Email is required",
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                        message: "Invalid email address"
                                    }
                                }}
                                render={({ field, fieldState: { error } }) => (
                                    <TextField
                                        {...field}
                                        label="Email"
                                        error={!!error}
                                        helperText={error?.message}
                                    />
                                )}
                            />

                            {/* User Type */}
                            <Controller
                                name="type"
                                control={methods.control}
                                defaultValue=""
                                rules={{ required: "User type is required" }}
                                render={({ field, fieldState: { error } }) => (
                                    <TextField
                                        {...field}
                                        select
                                        label="User Type"
                                        error={!!error}
                                        helperText={error?.message}
                                    >
                                        {Object.values(USER_TYPE).map((type) => (
                                            <MenuItem key={type} value={type}>
                                                {type}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                )}
                            />

                            {/* Corporation */}
                            <Controller
                                name="corporation"
                                control={methods.control}
                                defaultValue={null}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                    <Autocomplete
                                        value={value || null}
                                        onChange={(_, newValue) => onChange(newValue)}
                                        options={corporations}
                                        getOptionLabel={(option) => option?.name || ''}
                                        isOptionEqualToValue={(option, value) => {
                                            if (option.name === null || value.name === null) return true;
                                            return option?.id === value?.id;
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Corporation"
                                                error={!!error}
                                                helperText={error?.message}
                                            />
                                        )}
                                        ListboxProps={{
                                            onScroll: handleScroll
                                        }}
                                    />
                                )}
                            />

                            <Button
                                variant="contained"
                                type="submit"
                                sx={{ mt: 2 }}
                            >
                                Save Changes
                            </Button>
                        </Box>
                    </form>
                </FormProvider>
            </Box>
        </Modal>
    );
};

export default EditUserModal; 