import React from "react";
import { 
  Table, 
  TableBody, 
  TableCell, 
  TableRow, 
  IconButton,
  Collapse,
  Box,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  Tooltip
} from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import PersonIcon from '@mui/icons-material/PeopleOutline';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/EditOutlined';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import { ListCorporationItem, listCorporations } from "queries/corporations";
import { useInfiniteQuery } from '@tanstack/react-query';
import { ReactComponent as DeviceIconBlank } from '../../../icons/trap-icon-black.svg';
import { ReactComponent as CorpChartIconBlack } from '../../../icons/sub-corps-black.svg';

interface RowProps {
  data: ListCorporationItem;
  level: number;
  areMoreParentCorporations: boolean;
  onManage: (corporation: ListCorporationItem) => void;
  onAddSubCorp: (corporation: ListCorporationItem) => void;
  onDelete: (corporationId: string) => void;
  isDeleting: boolean;
}

const OrganisationRow: React.FC<RowProps> = ({
  data,
  level,
  areMoreParentCorporations,
  onManage,
  onAddSubCorp,
  onDelete,
  isDeleting
}) => {
  const [open, setOpen] = React.useState(level === 0 && areMoreParentCorporations);
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
  const hasSubCorporations = data.sub_corporations_count > 0;

  const {
    data: subCorpsData,
    isLoading: isLoadingSubCorps,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage
  } = useInfiniteQuery({
    queryKey: ['corporations', data.id],
    queryFn: ({ pageParam = 1 }) => 
      listCorporations({ page: pageParam, page_size: 20, parent__id: data.id }),
    getNextPageParam: (lastPage, pages) => {
      const totalPages = Math.ceil(lastPage.meta.count / 20);
      const nextPage = pages.length + 1;
      return nextPage <= totalPages ? nextPage : undefined;
    },
    enabled: open && hasSubCorporations,
  });

  const flattenedData = subCorpsData?.pages.flatMap(page => page.data) ?? [];

  const handleDeleteClick = () => {
    setDeleteDialogOpen(true)
  };

  return (
    <>
      <TableRow sx={{ 
        bgcolor: '#FFFFFF',
        borderBottom: open ? '1px solid rgba(224, 224, 224, 1)' : 'none',
        '&:hover': {
          bgcolor: 'rgba(39, 56, 44, 0.1)',
        },
      }}>
        <TableCell sx={{ 
          color: '#27382C'
        }}>
          <Box sx={{ paddingLeft: `${level * 2}rem`, display: 'flex', alignItems: 'center' }}>
            {hasSubCorporations ? (
              <IconButton 
                size="small" 
                onClick={() => setOpen(!open)}
              >
                {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
              </IconButton>
            ) : (
              <Box sx={{ width: 34, height: 34 }} />
            )}
            <Typography variant="body2" fontWeight='bold'>
              {data.name}
            </Typography>
          </Box>
        </TableCell>
        <TableCell colSpan={3} sx={{ pr: 2 }}>
          <Box sx={{ 
            display: 'flex',
            justifyContent: 'flex-end'
          }}>
            <Box sx={{ 
              display: 'flex', 
              alignItems: 'center',
              bgcolor: hasSubCorporations ? '#FDF1D5' : 'transparent',
              borderRadius: '24px',
              padding: '2px 16px',
              gap: 3,
              width: 'fit-content'
            }}>
              {hasSubCorporations && (
                <Box sx={{ display: 'flex', alignItems: 'center'}}>
                  <Typography variant="caption" fontWeight='bold'>
                  SUM
                  </Typography>
                </Box>
              )}
              <Box sx={{ display: 'flex', alignItems: 'center', minWidth: 60 }}>
                <PersonIcon sx={{ marginRight: 0.5, width: 24, height: 24 }} />
                <Typography variant="body2">
                  {data.users_count}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', minWidth: 60 }}>
                <Box sx={{ marginRight: 0.5, marginTop: 0.5 }}>
                  <DeviceIconBlank style={{ width: 24, height: 24 }} />
                </Box>
                <Typography variant="body2">
                  {data.devices.length}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', minWidth: 50 }}>
                <Box sx={{ marginRight: 1, mt: 0.5 }}>
                  <CorpChartIconBlack style={{ width: 24, height: 24 }} />
                </Box>
                <Typography variant="body2">
                  {data.sub_corporations_count}
                </Typography>
              </Box>
            </Box>
          </Box>
        </TableCell>
        <TableCell 
          align="right" 
          width="128px"
        >
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
            <Tooltip title="Add Sub-organization" arrow>
              <IconButton
                size="small"
                onClick={() => onAddSubCorp(data)}
                sx={{ 
                  color: '#27382C',
                  border: '1px solid rgba(39, 56, 44, 0.2)',
                  boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.10)',
                  padding: '4px',
                  '&:hover': {
                    border: '1px solid #27382C',
                    backgroundColor: 'rgba(39, 56, 44, 0.2)'
                  }
                }}
              >
                <AddIcon fontSize="small" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Edit Organization" arrow>
              <IconButton
                size="small"
                onClick={() => onManage(data)}
                sx={{ 
                  color: '#27382C',
                  border: '1px solid rgba(39, 56, 44, 0.2)',
                  boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.10)',
                  padding: '4px',
                  '&:hover': {
                    border: '1px solid #27382C',
                    backgroundColor: 'rgba(39, 56, 44, 0.2)'
                  }
                }}
              >
                <EditIcon fontSize="small" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete Organization" arrow>
              <IconButton
                size="small"
                onClick={handleDeleteClick}
                sx={{ 
                  color: '#AB0B0B',
                  border: '1px solid rgba(171, 11, 11, 0.2)',
                  boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.10)',
                  padding: '4px',
                  '&:hover': {
                    border: '1px solid #AB0B0B',
                    backgroundColor: 'rgba(171, 11, 11, 0.2)'
                  }
                }}
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Box>
        </TableCell>
      </TableRow>
      {hasSubCorporations && (
        <TableRow>
          <TableCell 
            style={{ 
              paddingBottom: 0,
              paddingTop: 0,
              paddingLeft: 0,
              paddingRight: 0,
              borderBottom: 'none'
            }} 
            colSpan={5}
          >
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ width: '100%' }}>
                {isLoadingSubCorps && !hasNextPage ? (
                  <Box sx={{ display: 'flex', justifyContent: 'center', py: 2 }}>
                    <CircularProgress size={24} sx={{ color: '#F4B71D' }} />
                  </Box>
                ) : (
                  <Table>
                    <TableBody>
                      {flattenedData.map((subCorp) => (
                        <OrganisationRow 
                          key={subCorp.id}
                          data={subCorp}
                          level={level + 1}
                          areMoreParentCorporations={areMoreParentCorporations}
                          onManage={onManage}
                          onAddSubCorp={onAddSubCorp}
                          onDelete={onDelete}
                          isDeleting={isDeleting}
                        />
                      ))}
                      {hasNextPage && (
                        <TableRow>
                          <TableCell colSpan={5}>
                            <Box sx={{ display: 'flex', justifyContent: 'center', py: 1 }}>
                              <Button 
                                onClick={() => fetchNextPage()}
                                sx={{ color: '#F4B71D' }}
                                disabled={isFetchingNextPage}
                              >
                                {isFetchingNextPage ? (
                                  <CircularProgress size={20} sx={{ color: '#F4B71D' }} />
                                ) : (
                                  'Load More'
                                )}
                              </Button>
                            </Box>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                )}
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}

      <Dialog 
        open={deleteDialogOpen} 
        onClose={() => !isDeleting && setDeleteDialogOpen(false)} 
        sx={{ '& .MuiDialog-paper': { borderRadius: '12px', minWidth: 400 } }}
      >
        <DialogTitle>
          {data.sub_corporations_count > 0 ? (
            <Typography variant="body1" fontWeight="bold">
              Delete Organization and Sub-organizations?
            </Typography>
          ) : (
            <Typography variant="body1" fontWeight="bold">
              Delete Organization?
            </Typography>
          )}
        </DialogTitle>
        <DialogContent>
          This action cannot be undone.
        </DialogContent>
        <DialogActions>
          <Button 
            size="small"
            variant="outlined" 
            onClick={() => setDeleteDialogOpen(false)}
            sx={{ textTransform: 'none' }}
          >
            Cancel
          </Button>
          <Button 
            size="small"
            variant="contained" 
            onClick={() => onDelete(data.id)} 
            color="error"
            sx={{ textTransform: 'none' }}
            disabled={isDeleting}
          >
            {isDeleting ? (
              <CircularProgress size={20} sx={{ color: 'inherit' }} />
            ) : (
              'Delete'
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}; 

export default OrganisationRow; 