import React, { useState, useCallback } from 'react';
import {
  Table,
  TableBody,
  TableContainer,
  Button,
  Box,
  TextField,
  CircularProgress,
  Typography,
} from '@mui/material';
import { useInfiniteQuery } from '@tanstack/react-query';
import DeviceDetailsRowItem from './DeviceDetailsRowItem';

export interface Device {
  id: string;
  smappId: string;
  cropName: string;
  corporationName: string | null;
  pestType: string;
  isAssigned: boolean;
}

interface DeviceDetailsTableProps {
  isAssignedView: boolean;
  fetchDevices: (page: number, search: string) => Promise<{ devices: Device[], nextPage: number | null }>;
  updateAssignedStatusForDevices: (deviceIds: string[], assign: boolean) => Promise<void>;
}

const DeviceDetailsTable: React.FC<DeviceDetailsTableProps> = ({
  isAssignedView,
  fetchDevices,
  updateAssignedStatusForDevices
}) => {
  const [selectedDevices, setSelectedDevices] = useState<string[]>([]);
  const [searchTerm, setSearchTerm] = useState('');

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
  } = useInfiniteQuery(
    [isAssignedView ? 'assignedDevices' : 'devices', isAssignedView, searchTerm],
    ({ pageParam = 1 }) => fetchDevices(pageParam, searchTerm),
    {
      getNextPageParam: (lastPage) => lastPage.nextPage,
    }
  );

  const devices = data?.pages.flatMap(page => page.devices) || [];

  const handleScroll = useCallback((event: React.UIEvent<HTMLDivElement>) => {
    const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
    if (scrollHeight - scrollTop <= clientHeight * 1.5 && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage, isFetchingNextPage]);

  const handleSelectDevice = (deviceId: string, isSelected: boolean) => {
    if (isSelected) {
      setSelectedDevices([...selectedDevices, deviceId]);
    } else {
      setSelectedDevices(selectedDevices.filter((id) => id !== deviceId));
    }
  };

  const handleDeselectAll = () => {
    setSelectedDevices([]);
  };

  const handleAssignSelected = (singleDeviceId?: string) => {
    updateAssignedStatusForDevices(singleDeviceId ? [singleDeviceId] : selectedDevices, true);
    setSelectedDevices([]);
  };

  const handleUnassignSelected = (singleDeviceId?: string) => {
    updateAssignedStatusForDevices(singleDeviceId ? [singleDeviceId] : selectedDevices, false);
    setSelectedDevices([]);
  };

  const handleRemoveAll = () => {
    updateAssignedStatusForDevices(devices.map((device) => device.id), false);
  };

  return (
    <Box
      sx={{
        border: '1px solid #D4D7D5',
        borderRadius: '24px',
        padding: '20px',
        height: '70vh',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <Typography variant="body1" sx={{ fontWeight: 'bold', color: '#27382C' }}>
        {isAssignedView ? 'Assigned devices' : 'All devices'}
      </Typography>
      {devices.length === 0 && (

        <Typography variant="caption" sx={{ marginBottom: 2, color: '#27382C' }}>
          {isAssignedView ?
            "Looks like there were no devices assigned yet. You can assign devices from the 'All devices' table." :
            "There are no devices available for assignment."
          }
        </Typography>
      )}
      {(devices.length > 0 || searchTerm) && (
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center', 
          mt: 2, 
          mb: 1 
        }}>
          <TextField
            size="small"
            placeholder="Search devices..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            sx={{ minWidth: '100px', maxWidth: '300px' }}
          />

          <Box>
            <Button
              variant="outlined"
              size="small"
              onClick={handleDeselectAll}
              sx={{
                mr: 1,
                height: '32px',
                minHeight: '32px'
              }}
              disabled={selectedDevices.length === 0}
            >
              Deselect All
            </Button>
            <Button
              disabled={selectedDevices.length === 0}
              variant="contained"
              size="small"
              onClick={isAssignedView ?
                () => handleUnassignSelected() :
                () => handleAssignSelected()
              }
              sx={{
                height: '32px',
                minHeight: '32px'
              }}
            >
              {isAssignedView ? 'Remove Selected' : 'Assign Selected'}
            </Button>
          </Box>
        </Box>
      )}

      {isAssignedView && devices.length > 0 && (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mr: 2, mt: 1 }}>
          <Button
            variant="text"
            size="small"
            onClick={handleRemoveAll}
            sx={{
              textTransform: "none",
              color: '#27382C',
              fontWeight: 'bold',
              textDecoration: 'underline',
              padding: 0,
              minWidth: 0,
              alignSelf: 'flex-end'
            }}
          >
            {hasNextPage
              ? `Remove all loaded (${devices.length})`
              : 'Remove all'
            }
          </Button>
        </Box>
      )}

      {!isAssignedView && devices.length > 0 && (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mr: 2, mt: 1 }}>
          <Button
            variant="text"
            size="small"
            onClick={() => updateAssignedStatusForDevices(devices.map(device => device.id), true)}
            sx={{
              textTransform: "none",
              color: '#27382C',
              fontWeight: 'bold',
              textDecoration: 'underline',
              padding: 0,
              minWidth: 0,
              alignSelf: 'flex-end'
            }}
          >
            {hasNextPage
              ? `Assign all loaded (${devices.length})`
              : 'Assign all'
            }
          </Button>
        </Box>
      )}

      <TableContainer
        sx={{
          flexGrow: 1,
          overflow: 'auto',
          mb: 2,
          paddingRight: '6px'
        }}
        onScroll={handleScroll}
      >
        <Table sx={{ borderCollapse: 'separate', borderSpacing: '0 8px' }}>
          <TableBody>
            {devices.map((device) => (
              <DeviceDetailsRowItem
                key={device.id}
                smappId={device.smappId}
                cropName={device.cropName}
                corporationName={device.corporationName}
                pestType={device.pestType}
                isAssigned={device.isAssigned}
                isSelected={selectedDevices.includes(device.id)}
                handleAssignClick={() => handleAssignSelected(device.id)}
                handleUnassignClick={() => handleUnassignSelected(device.id)}
                setIsSelected={(isSelected) => handleSelectDevice(device.id, isSelected)}
              />
            ))}
          </TableBody>
        </Table>
        {(isLoading || isFetchingNextPage) && (
          <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
            <CircularProgress />
          </Box>
        )}
      </TableContainer>
    </Box>
  );
};

export default DeviceDetailsTable;
