export const API_ROUTES = {
  ACCESS_TOKEN: "/api/auth/",
  REFRESH_TOKEN: "/api/auth/refresh/",
  REGISTER_USER: "/api/register/",
};

const ROUTES = {
  HOME: "/",
  DASHBOARD: "/dashboard",
  DEVICES: "/devices",
  DEVICES_CONFIG: "/devices/config",
  FIRMWARES: "/firmwares",
  MANAGEMENT: "/management",
  USER_MANAGEMENT: "/user-management",
  VALIDATOR_MANAGEMENT: "/validator-management",
  CORPORATION_MANAGEMENT: "/corporation-management",
  MAP: "/map",
  ORCHARD: "/orchard",
  ORCHARD_MANAGER: "/orchardManager",
  VALIDATOR_DEVICES: "/validator-devices",
  LOGIN: "/login",
  REGISTER: "/register",
  MY_PROFILE: "/my-profile",
  EXPORT: "/export",
};

export default ROUTES;
