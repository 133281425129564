import { Box, ListSubheader, Paper, Typography } from "@mui/material";
import DetectionCountDeltaChart, {
  DC_CHART_CONFIG,
  DetectionCountDeltaChartProps,
  DetectionCountDeltaSummedChart,
  DetectionCountsByDevicesChart,
  DetectionCountsByOrchardsChart,
} from "components/visualization/DetectionCountDeltaChart";
import useDateRange from "hooks/useDateRange";
import React from "react";
import { capitalizeFirstLetter } from "utils/commons";
import { Aggregations, Frequency } from "utils/query";

type DetectionCountDeltaChartPanelProps = Pick<DetectionCountDeltaChartProps, "deviceIdIn">;

export const DetectionCountDeltaSummedChartPanel: React.FC<
  DetectionCountDeltaChartPanelProps & { title?: string; frequency?: string; orchard?: string }
> = ({ deviceIdIn, frequency, title, orchard }) => {
  const { dateRange, form: dateRangeForm } = useDateRange({ defaultDuration: { days: 30 * 12 } });

  const msg = `Detection count delta ${title}`;

  return (
    <Paper
      elevation={3}
      sx={{
        pt: 3,
        pb: 2,
        px: 2,
        height: "min(400px, calc(100vw / 2))",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box sx={{ mb: 1, display: "flex" }}>
        <Typography variant="body2" fontWeight="bold" sx={{ flex: 1 }}>{msg}</Typography>
        {dateRangeForm}
      </Box>
      <Box sx={{ flex: 1 }}>
        <DetectionCountDeltaSummedChart
          frequency={frequency || "day"}
          deviceIdIn={deviceIdIn}
          orchard={orchard}
          from={dateRange.from}
          to={dateRange.to}
          chartConfig={[{ ...DC_CHART_CONFIG, name: msg }]}
        />
      </Box>
    </Paper>
  );
};

export const DetectionCountsByOrchardsChartPanel: React.FC<
  DetectionCountDeltaChartPanelProps & {
    title?: string;
    width?: string;
    frequency?: Frequency;
    orchardID: string;
    aggregations: Aggregations;
  }
> = ({ deviceIdIn, frequency, title, orchardID, aggregations, width }) => {
  const { dateRange, form: dateRangeForm } = useDateRange({ defaultDuration: { days: 30 * 12 } });

  const freq = frequency || "day";
  const msg = title || `${capitalizeFirstLetter(aggregations)} Detection count delta by ${freq}`;

  return (
    <Paper
      elevation={3}
      sx={{
        pt: 3,
        pb: 2,
        px: 2,
        height: "min(400px, calc(100vw / 2))",
        display: "flex",
        flexDirection: "column",
        width,
      }}
    >
      <Box sx={{ mb: 1, display: "flex" }}>
        <Typography variant="body2" fontWeight="bold" sx={{ flex: 1 }}>{msg}</Typography>
        {dateRangeForm}
      </Box>
      <Box sx={{ flex: 1 }}>
        <DetectionCountsByOrchardsChart
          frequency={freq}
          aggregations={aggregations}
          orchardID={orchardID}
          deviceIdIn={deviceIdIn}
          from={dateRange.from}
          to={dateRange.to}
          chartConfig={[{ ...DC_CHART_CONFIG, name: msg }]}
        />
      </Box>
    </Paper>
  );
};

export const DetectionCountsByDevicesChartPanel: React.FC<
  DetectionCountDeltaChartPanelProps & {
    title?: string;
    frequency?: Frequency;
    deviceID: string;
    aggregations: Aggregations;
  }
> = ({ frequency, title, deviceID, aggregations }) => {
  const { dateRange, form: dateRangeForm } = useDateRange({ defaultDuration: { days: 30 * 12 } });

  const freq = frequency || "day";
  const msg = title || `${capitalizeFirstLetter(aggregations)} Detection count delta by ${freq}`;

  return (
    <Paper
      elevation={3}
      sx={{
        pt: 3,
        pb: 2,
        px: 2,
        height: "min(400px, calc(100vw / 2))",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box sx={{ mb: 1, display: "flex" }}>
        <Typography variant="body2" fontWeight="bold" sx={{ flex: 1 }}>{msg}</Typography>
        {dateRangeForm}
      </Box>
      <Box sx={{ flex: 1 }}>
        <DetectionCountsByDevicesChart
          frequency={freq}
          aggregations={aggregations}
          deviceID={deviceID}
          from={dateRange.from}
          to={dateRange.to}
          chartConfig={[{ ...DC_CHART_CONFIG, name: msg }]}
        />
      </Box>
    </Paper>
  );
};

const DetectionCountDeltaChartPanel: React.FC<
  DetectionCountDeltaChartPanelProps & {
    title?: string;
    frequency?: string;
    orchard?: string;
    corporationId?: string;
  }
> = ({ deviceIdIn, frequency, title, orchard, corporationId }) => {
  const { dateRange, form: dateRangeForm } = useDateRange({ defaultDuration: { days: 30 * 12 } });

  const msg = deviceIdIn?.length === 1 ? "Detection count delta" : "Average detection count delta";

  return (
    <Paper
      sx={{
        pt: 1,
        pb: 2,
        px: 2,
        height: "min(400px, calc(100vw / 2))",
        display: "flex",
        flexDirection: "column",
        borderRadius: "24px",
        boxShadow: "2",
      }}
    >
      <Box sx={{ mb: 2, display: "flex" }}>
        <Typography variant="body2" fontWeight="bold" sx={{ flex: 1 }}>
          {msg}
        </Typography>
      </Box>
      <Box
        sx={{
          mb: 1,
          mr: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        {dateRangeForm}
      </Box>
      <Box sx={{ flex: 1 }}>
        <DetectionCountDeltaChart
          frequency={frequency || "day"}
          deviceIdIn={deviceIdIn}
          corporationId={corporationId}
          from={dateRange.from}
          to={dateRange.to}
          chartConfig={[{ ...DC_CHART_CONFIG, name: msg }]}
        />
      </Box>
    </Paper>
  );
};

export default DetectionCountDeltaChartPanel;
