import React, { useState } from "react";
import { 
    TableRow, 
    TableCell, 
    IconButton, 
    Box, 
    Button, 
    Dialog, 
    DialogActions, 
    DialogContent, 
    DialogContentText, 
    DialogTitle 
} from "@mui/material";
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import Tooltip from '@mui/material/Tooltip';
import SettingsIcon from '@mui/icons-material/SettingsOutlined';
import { ReactComponent as TrapIcon } from '../../../icons/trap-icon-black.svg';

interface ValidatorItemProps {
    name: string;
    numberOfDevices: number;
    handleDeleteClick: () => void;
    handleAssignDevicesClick: () => void;
}

const ValidatorItem: React.FC<ValidatorItemProps> = ({
    name,
    numberOfDevices,
    handleDeleteClick,
    handleAssignDevicesClick
}) => {
    const [openDialog, setOpenDialog] = useState(false);

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleConfirmDelete = () => {
        handleDeleteClick();
        setOpenDialog(false);
    };

    return (
        <>
            <TableRow sx={{ 
                height: 68,
                '& .MuiTableCell-root': { 
                    borderBottom: '1px solid #D4D7D5',
                    fontSize: '16px',
                }
            }}>
                <TableCell sx={{ pl: 4 }}>{name}</TableCell>
                <TableCell sx={{ pl: 4 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <TrapIcon style={{ width: 24, height: 24 }} />
                        <Box component="span" sx={{ ml: 1 }}>{numberOfDevices}</Box>
                    </Box>
                </TableCell>
                <TableCell align="right" sx={{ pl: 4, pr: 4 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
                        <Tooltip title="Manage devices" arrow>
                            <IconButton
                                size="small"
                                onClick={handleAssignDevicesClick}
                                sx={{ 
                                    color: '#27382C',
                                    border: '1px solid rgba(39, 56, 44, 0.2)',
                                    boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.10)',
                                    padding: '4px',
                                    '&:hover': {
                                        border: '1px solid #27382C',
                                        backgroundColor: 'rgba(39, 56, 44, 0.2)'
                                    }
                                }}
                            >
                                <SettingsIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete Validator" arrow>
                            <IconButton
                                size="small"
                                onClick={handleOpenDialog}
                                sx={{ 
                                    color: '#AB0B0B',
                                    border: '1px solid rgba(171, 11, 11, 0.2)',
                                    boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.10)',
                                    padding: '4px',
                                    '&:hover': {
                                        border: '1px solid #AB0B0B',
                                        backgroundColor: 'rgba(171, 11, 11, 0.2)'
                                    }
                                }}
                            >
                                <DeleteIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </Box>
                </TableCell>
            </TableRow>

            {/* Confirmation Dialog */}
            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                PaperProps={{
                    style: {
                        borderRadius: '16px'
                    }
                }}
            >
                <DialogTitle>Confirm Deletion</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete {name}? This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} variant="outlined">
                        Cancel
                    </Button>
                    <Button onClick={handleConfirmDelete} variant="contained" color="error">
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default ValidatorItem;
