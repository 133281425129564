import React, { useMemo, useRef } from "react";
import AutoCompleteWithFilter from "components/AutoCompleteWithFilter";
import { Autocomplete, Box, Card, TextField } from "@mui/material";
import { listCrops } from "queries/crops";
import { listPests } from "queries/pests";
import { clearListChars, makeUniquListFromStrin } from "utils/react";
import countryList from "react-select-country-list";
import FilteredDeviceList from "./FilteredDeviceList";
import { defaultMyFilter } from "./Contents/utils/defaults";
import { CurrentDeviceConfigType, MyFilter, MyFilterData } from "./Contents/utils/types";

const Filter: React.FC<{
  currentDeviceConfig: CurrentDeviceConfigType;
}> = ({ currentDeviceConfig }) => {
  const timerRef: { current: NodeJS.Timeout | null } = useRef(null);

  const [filter, setFilter] = React.useState<MyFilter>(defaultMyFilter);
  const countries = useMemo(() => countryList().getData(), []);

  const filterCard = useMemo(() => {
    const filterChange = (keyOfMyFilter: string, data: Partial<MyFilterData>) => {
      clearTimeout(timerRef.current || undefined);
      timerRef.current = setTimeout(() => {
        // update filter value by key
        setFilter((preFilter) => {
          return Object.entries(preFilter).reduce((_preFilter: any, [k, v]) => {
            _preFilter[k] = v;
            if (k === keyOfMyFilter) {
              _preFilter[k] = { ...v, value: data.value === "" ? null : data.value };
            }
            return _preFilter;
          }, {});
        });
      }, 400);
    };

    const handleChange = (name: string, value: string) => {
      const tmpVal = clearListChars(value);
      filterChange(name, {
        value: makeUniquListFromStrin(tmpVal),
      } as Partial<MyFilterData>);
    };

    const filterKeysStyle = { fontSize: "1px", flexBasis: "49%" };

    const filterKeys = [
      {
        name: "Type",
        value: ["Mini", "Mini2", "MiniV2", "Premium", "Undefined"].map((v) => ({
          label: v,
          value: v.toLowerCase(),
        })),
        muiType: "auto",
      },
      {
        name: "Country",
        value: countries.map((v) => ({
          label: `${v.label} - ${v.value}`,
          value: v.value,
        })),
        muiType: "auto",
      },
      {
        name: "Pest",
        data: (
          <AutoCompleteWithFilter
            boxSx={{ fontSize: "1px", flexBasis: "49%" }}
            key="AtConfigPestFilter"
            label="Pest"
            handleChange={(name, value) => {
              handleChange(name, value);
            }}
            qKey="pest"
            qFn={listPests}
            queryParamsKey={["name__icontains"]}
          />
        ),
      },
      {
        name: "Crop",
        data: (
          <AutoCompleteWithFilter
            boxSx={{ fontSize: "1px", flexBasis: "49%" }}
            key="AtConfigCorpFilter"
            label="Crop"
            handleChange={(name, value) => {
              handleChange(name, value);
            }}
            qKey="crop"
            qFn={listCrops}
            queryParamsKey={["name__icontains"]}
          />
        ),
      },
      {
        name: "smapp_id",
        value: [
          {
            label: "Smapp id",
            value: filter.smapp_id.value || "",
          },
        ],
        muiType: "text",
      },
      {
        name: "imei",
        value: [
          {
            label: "Imei",
            value: "",
          },
        ],
        muiType: "text",
      },
      {
        name: "mac",
        value: [
          {
            label: "Mac",
            value: "",
          },
        ],
        muiType: "text",
      },
    ].map((v) => {
      if (v.data !== undefined) {
        return v.data;
      }
      if (v.muiType === "auto") {
        return (
          <Autocomplete
            key={v.name}
            sx={filterKeysStyle}
            options={v.value}
            onChange={(e, value) => {
              handleChange(v.name.toLowerCase(), value ? value.value : "");
            }}
            isOptionEqualToValue={(option, value) =>
              option.value === value.value || value.label === ""
            }
            renderInput={(params) => <TextField {...params} label={v.name} variant="outlined" />}
          />
        );
      }
      return (
        <TextField
          key={v.name}
          sx={filterKeysStyle}
          name={v.name}
          label={v.value[0].label}
          onChange={(e) => {
            handleChange(e.target.name, e.target.value);
          }}
          onBlur={(e) => {
            setTimeout(() => {
              e.target.value = v.value[0].value;
            }, 1000);
          }}
        />
      );
    });

    return (
      <Box sx={{ margin: "1px", padding: "1px" }}>
        <Card sx={{ margin: "5px", padding: "5px" }}>
          Filter:
          <Box
            sx={{
              padding: "5px",
              display: "flex",
              width: "100%",
              flexFlow: "row",
              gap: "10px",
              flexWrap: "wrap",
            }}
          >
            {filterKeys.map((v) => {
              return v;
            })}
          </Box>
        </Card>
      </Box>
    );
  }, [countries, filter]);

  return (
    <Box
      className="FilterContainer"
      sx={{
        display: "flex",
        flexFlow: "column",
      }}
    >
      {filterCard}
      <FilteredDeviceList filter={filter} currentDeviceConfig={currentDeviceConfig} />
    </Box>
  );
};

export default Filter;
