import React from "react";
import { 
  Table, 
  TableBody, 
  TableContainer, 
  Paper,
  Box,
  Typography,
  Button,
  TextField,
  CircularProgress
} from "@mui/material";
import AddIcon from '@mui/icons-material/AddCircleOutline';
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined';
import OrganisationRow from '../components/OrganisationRow';
import useOrganisationManagement from './useOrganisationManagement';
import AddOrganisationModal from '../components/AddOrganisationModal';
import ManageOrganisationModal from '../components/ManageOrganisationModal';

const OrganisationManagementMain = () => {
  const { 
    corporations, 
    areMoreParentCorporations,
    loading, 
    error, 
    isAddModalOpen, 
    setIsAddModalOpen, 
    isManageModalOpen,
    setIsManageModalOpen,
    selectedCorporation,
    setSelectedCorporation,
    updateCorporationSubmit,
    handleAddCorporation,
    addCorporationError,
    manageCorporationError,
    searchQuery, 
    setSearchQuery,
    hasMorePages,
    loadMore,
    isLoadingMore,
    setParentSearchTerm,
    parentCorporations,
    parentCorporation,
    handleParentCorporationScroll,
    isFetchingNextParentPage,
    handleDeleteCorporation,
    isLoadingParentCorporation,
    isFetchingParentCorporations,
    isDeleting,
    isUpdateSuccess
  } = useOrganisationManagement();

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <>
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        mb: 4
      }}>
        <Typography variant="h6">
          Organization Management
        </Typography>
        <Button
          variant="contained"
          endIcon={<AddIcon />}
          sx={{ textTransform: 'none' }}
          onClick={() => setIsAddModalOpen(true)}
        >
          Add Organization
        </Button>
      </Box>

      {areMoreParentCorporations && (
        <Box sx={{ mb: 3 }}>
          <TextField
            variant="outlined"
            placeholder="Search organization..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            size="small"
            InputProps={{
              sx: { 
                bgcolor: 'background.paper',
                '&:hover': {
                  bgcolor: 'background.paper',
                }
              }
            }}
          />
        </Box>
      )}

      {loading ? (
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center', 
          minHeight: '200px' 
        }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <AddOrganisationModal
            open={isAddModalOpen}
            onClose={() => setIsAddModalOpen(false)}
            addCorporationError={addCorporationError}
            setParentSearchTerm={setParentSearchTerm}
            parentCorporations={parentCorporations}
            handleParentCorporationScroll={handleParentCorporationScroll}
            isFetchingNextParentPage={isFetchingNextParentPage}
            isFetchingParentCorporations={isFetchingParentCorporations}
            handleAddModalSave={handleAddCorporation}
            selectedCorporation={selectedCorporation}
          />

          <ManageOrganisationModal
            open={isManageModalOpen}
            onClose={() => setIsManageModalOpen(false)}
            onSave={updateCorporationSubmit}
            isUpdateSuccess={isUpdateSuccess}
            error={manageCorporationError}
            currentCorporation={selectedCorporation || undefined}
            parentCorporation={parentCorporation}
            isLoadingParentCorporation={isLoadingParentCorporation}
            setParentSearchTerm={setParentSearchTerm}
            parentCorporations={parentCorporations}
            handleParentCorporationScroll={handleParentCorporationScroll}
            isFetchingNextParentPage={isFetchingNextParentPage}
            isFetchingParentCorporations={isFetchingParentCorporations}
          />

          {corporations.map((corp) => (
            <TableContainer 
              key={corp.id} 
              component={Paper} 
              sx={{ 
                marginBottom: 2,
                borderRadius: '16px',
                '& .MuiPaper-root': {
                  borderRadius: '24px',
                }
              }}
            >
              <Table>
                <TableBody>
                  <OrganisationRow 
                    data={corp} 
                    level={0}
                    areMoreParentCorporations={areMoreParentCorporations}
                    onManage={(corporation) => {
                      setIsManageModalOpen(true);
                      setSelectedCorporation(corporation);
                    }}
                    onAddSubCorp={(corporation) => {
                      setIsAddModalOpen(true);
                      setSelectedCorporation(corporation);
                    }}
                    onDelete={handleDeleteCorporation}
                    isDeleting={isDeleting}
                  />
                </TableBody>
              </Table>
            </TableContainer>
          ))}

          {hasMorePages && (
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2, mb: 4 }}>
              <Button
                variant="text"
                onClick={() => loadMore()}
                disabled={isLoadingMore}
                endIcon={!isLoadingMore && <ExpandCircleDownOutlinedIcon/>}
                sx={{ 
                  textTransform: 'none',
                  '& .MuiTypography-root': {
                    fontWeight: 'bold'
                  }
                }}
              >
                <Typography>
                  {isLoadingMore ? <CircularProgress size={16} /> : 'Load more'}
                </Typography>
              </Button>
            </Box>
          )}
        </>
      )}
    </>
  );
};

export default OrganisationManagementMain;
