import { LatLng } from "leaflet";
import React from "react";
import { NumberParam, useQueryParam } from "use-query-params";
import { isNotNil } from "utils/lodash";

const DEFAULT_CENTER_X = 47.497913;
const DEFAULT_CENTER_Y = 19.040236;
const DEFAULT_ZOOM = 12;

type UseMapSettingsProps = {
  firstDeviceLocation?: { lat: number; lng: number };
};

const useMapSettings = ({ firstDeviceLocation }: UseMapSettingsProps) => {
  const [centerXParam, setCenterXParam] = useQueryParam("center-x", NumberParam);
  const [centerYParam, setCenterYParam] = useQueryParam("center-y", NumberParam);
  const [zoomParam, setZoomParam] = useQueryParam("zoom", NumberParam);

  const center = React.useMemo(() => {
    if (isNotNil(centerXParam) && isNotNil(centerYParam)) {
      return new LatLng(centerXParam, centerYParam);
    }
    if (firstDeviceLocation) {
      return new LatLng(firstDeviceLocation.lat, firstDeviceLocation.lng);
    }
    return new LatLng(DEFAULT_CENTER_X, DEFAULT_CENTER_Y);
  }, [centerXParam, centerYParam, firstDeviceLocation]);

  const setCenter = React.useCallback(
    (value: LatLng) => {
      const { lat: latitude, lng: longitude } = value;
      setCenterXParam(latitude);
      setCenterYParam(longitude);
    },
    [setCenterXParam, setCenterYParam]
  );

  const zoom = zoomParam || DEFAULT_ZOOM;
  const setZoom = React.useCallback((value: number) => setZoomParam(value), [setZoomParam]);

  return React.useMemo(
    () => ({
      center,
      setCenter,
      zoom: zoom || undefined,
      setZoom,
    }),
    [center, setCenter, setZoom, zoom]
  );
};

export default useMapSettings;
