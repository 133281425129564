import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { IMAGE_TYPES, ListImageItem, listImages, ListImagesParams } from "queries/devices/images";
import { Grid, Paper } from "@mui/material";
import NothingToShow from "components/NothingToShow";
import LoadingImage from "components/Image/LoadingImage";
import { ImageEdit } from "components/Image/ImageEdit";
import ChainValidationProvider from "context/ChainValidationProvider";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
};

interface ValidationModalProps {
  deviceID: string;
  imagesArray?: ListImageItem[];
  isOpen: boolean;
  onClose: () => void;
}

const ValidationModal: React.FC<ValidationModalProps> = ({
  deviceID,
  imagesArray,
  isOpen,
  onClose,
}) => {
  const [imageIndex, setImageIndex] = useState(0);
  const queryClient = useQueryClient();

  const params: ListImagesParams = {
    page: 1,
    page_size: 1,
    device__id: deviceID,
    created_at__lte:
      imagesArray && imagesArray!.length > imageIndex ? imagesArray[imageIndex].created_at : 0,
    ordering: "-created_at",
    is_validated: true,
  };

  const previousValidatedImagesQuery = useQuery({
    queryKey: ["list-all-images", params],
    queryFn: async () => (await listImages(params)).data,
    onError: (e) => {
      console.error("Failed to fetch images: ", e);
    },
  });

  const handleClose = () => {
    queryClient.invalidateQueries({ queryKey: ["list-all-images"] });
    setImageIndex(0);

    if (onClose) {
      onClose();
    }
  };

  React.useEffect(() => {
    if (imagesArray?.length === 0) {
      onClose();
    }
  }, [imagesArray, onClose]);

  let content = <NothingToShow />;
  if (previousValidatedImagesQuery.isLoading) {
    content = <LoadingImage />;
  } else if (imagesArray && imagesArray.length > imageIndex) {
    let initialCoordinates = imagesArray[imageIndex].coordinates;

    if (previousValidatedImagesQuery.data?.length === 1 && initialCoordinates.length === 0) {
      initialCoordinates = previousValidatedImagesQuery.data[0].coordinates;
    }

    content = (
      <ChainValidationProvider
        imageIDsArray={imagesArray.map((e) => e.id)}
        imageIndex={imageIndex}
        setImageIndex={setImageIndex}
        onClose={handleClose}
      >
        <ImageEdit
          id={imagesArray[imageIndex].id}
          saveAs={IMAGE_TYPES.validated}
          onClose={() => {
            handleClose();
          }}
          initialCoordinates={initialCoordinates}
          mode="validation"
          isVisible={imagesArray[imageIndex].is_visible}
        />
      </ChainValidationProvider>
    );
  }

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Grid item sm={12}>
          <Paper
            elevation={3}
            sx={{
              p: 3,
              height: "100%",
              display: "flex",
              flexDirection: "column",
              position: "relative",
            }}
          >
            {content}
          </Paper>
        </Grid>
      </Box>
    </Modal>
  );
};

export default ValidationModal;
