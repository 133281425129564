/* eslint-disable import/prefer-default-export */
import axios from "axios";
import { listAllFactory } from "utils/query";
import { ListResponse } from "./types";
import { ListDeviceItem } from "./devices";

export type ListCorporationParams = {
  page: number;
  page_size: number;
  search?: string;
  id__in?: string[];
  parent__id?: string;
  parent_isnull?: boolean;
};
export type ListCorporationItem = {
  id: string;
  created_at: number;
  name: string;
  parent_id: string | null;
  users_count: number;
  sub_corporations_count: number;
  devices: Pick<ListDeviceItem, "id" | "smapp_id" | "crop_name" | "corporation_name" | "pest_name">[];
}

export const listCorporations = async (
  params?: ListCorporationParams
): Promise<ListResponse<ListCorporationItem>> => {
  const { data } = await axios.get<ListResponse<ListCorporationItem>>("/api/corporations", {
    params,
  });
  return data;
};

export const listAllCorporations = listAllFactory(listCorporations);

export type DetailCorporationParams = {
  id: string;
};
type DetailCorporationItem = ListCorporationItem;
export const detailCorporation = async ({
  id,
}: DetailCorporationParams): Promise<DetailCorporationItem> => {
  const { data } = await axios.get<DetailCorporationItem>(`/api/corporations/${id}/`);
  return data;
};

export type CreateCorporationParams = {
  name?: string;
  parent_id?: string | null;
  devices_to_add?: string[];
  devices_to_remove?: string[];
};

export const createCorporation = async (
  params: CreateCorporationParams
): Promise<DetailCorporationItem> => {
  const { data } = await axios.post<DetailCorporationItem>(`/api/corporations/`, params);
  return data;
};

export type UpdateCorporationParams = {
  id: string;
  params: CreateCorporationParams;
};

export const updateCorporation = async (params: UpdateCorporationParams): Promise<DetailCorporationItem> => {
  const { data } = await axios.patch<DetailCorporationItem>(`/api/corporations/${params.id}/`, params.params);
  return data;
};

export const deleteCorporation = async (id: string): Promise<void> => {
  await axios.delete(`/api/corporations/${id}/`);
};

export const getCorporationById = async (id: string): Promise<DetailCorporationItem> => {
  const { data } = await axios.get<DetailCorporationItem>(`/api/corporations/${id}/`);
  return data;
};

export type GetCorporationDevicesByIdParams = {
  id: string;
  search?: string | null;
  page: number;
  page_size: number;
};

export type CorporationDeviceResponse = {
  results: Pick<ListDeviceItem, "id" | "smapp_id" | "crop_name" | "corporation_name" | "pest_name">[];
  count: number;
  next: string | null;
  previous: string | null;
};

export const getCorporationDevicesById = async ({ 
  id, 
  search, 
  page = 1, 
  page_size = 10 
}: GetCorporationDevicesByIdParams): Promise<CorporationDeviceResponse> => {
  const { data } = await axios.get<CorporationDeviceResponse>(`/api/corporations/${id}/devices/`, {
    params: { 
      search,
      page,
      page_size
    },
  });
  return data;
};