import React from "react";
import { Box, Card } from "@mui/material";
import { ListDeviceItem } from "queries/devices";
import DeviceTable from "./deviceTable";

const DeviceManagement = () => {
  const [devices, setDevices] = React.useState<ListDeviceItem[]>([]);
  return (
    <Box>
      <Card sx={{ display: "flex", flexFlow: "column", p: 4, m: 1, gap: 4, flex: 5, borderRadius: 6 }}>
        <DeviceTable setDevices={setDevices} />
      </Card>
    </Box>
  );
};

export default DeviceManagement;
