import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import { Box, Button, Container, SxProps, Theme, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { Loader } from "components/Loader";
import { listAllDeviceOrchards, ListDeviceOrchardItem } from "queries/devices/orchards";
import React from "react";
import { useNavigate } from "react-router-dom";
import { changePageTitle } from "utils/commons";
import GroupedOrchardView from "./GroupedOrchard";

type OrchardManagerButtonProps = {
  title: string;
  sx?: SxProps<Theme> | undefined;
};
const OrchardManagerButton = ({ title, sx }: OrchardManagerButtonProps) => {
  const navigate = useNavigate();

  return (
    <Button
      sx={sx || { marginLeft: "auto" }}
      onClick={() => navigate("/orchardManager")}
      variant="contained"
    >
      {title}
    </Button>
  );
};

const OrchardMain = () => {
  React.useEffect(() => {
    changePageTitle("Orchards");
  }, []);
  const { data: deviceOrchards, isLoading } = useQuery({
    queryFn: () => listAllDeviceOrchards(),
    queryKey: ["list-all-device-orchards"],
  });

  const allDeviceOrchards = deviceOrchards?.data || ([] as ListDeviceOrchardItem[]);

  const orchardManagerButton = <OrchardManagerButton title="Trap Group Management" />;
  let orchardCardItems = null;

  if (allDeviceOrchards.length !== 0) {
    orchardCardItems = <GroupedOrchardView allDeviceOrchards={allDeviceOrchards} />;
  } else {
    orchardCardItems = (
      <Box
        sx={{
          display: "flex",
          height: "500px",
          gap: 2,
          flexFlow: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {(!isLoading && (
          <>
            <HourglassEmptyIcon sx={{ fontSize: "200px" }} />
            <Typography variant="subtitle1" textAlign="center">
              You haven&apos;t created any trap groups yet.
              <br /> Please go to the Trap Group Management page and create your first trap group.
            </Typography>
            <OrchardManagerButton title="Add new trap group" sx={{ fontSize: 10 }} />
          </>
        )) || <Loader />}
      </Box>
    );
  }

  return (
    <Container
      className="OrchardMain"
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 5,
        justifyContent: "center",
        width: "100%",
        maxWidth: "100% !important",
        p: 0,
        m: 0,
      }}
    >
      <Box sx={{ display: "flex" }}>
        <Typography variant="h4" sx={{ flexGrow: 1 }}>
          Trap Groups
        </Typography>
        {orchardManagerButton}
      </Box>
      {orchardCardItems}
    </Container>
  );
};

export default OrchardMain;
