import React from "react";
import { Route, Routes } from "react-router-dom";
import ProtectedRoute from "components/ProtectedRoute";
import AppWrapper from "components/AppWrapper";
import UserManagementMain from "./main";

const UserManagement = () => {
    return (
      <Routes>
        <Route
          index
          element={
            <ProtectedRoute>
              <AppWrapper mainSxProps={{ width: "100%", maxWidth: "100%" }}>
                <UserManagementMain />
              </AppWrapper>
            </ProtectedRoute>
          }
        />
      </Routes>
    );
};

export default UserManagement;
