import React from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { useMutation, useQueryClient, useQuery, useInfiniteQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { registration, USER_TYPE, ListUserItem, ListUserParams, listUsers, updateUser, deleteUser } from "queries/users";
import { ListCorporationItem, listCorporations } from "queries/corporations";
import useCustomSnackBar from "hooks/useCustomSnackBar";
import _, { debounce } from "lodash";

type UserMngmntSubmitType = {
  id?: string;
  name: string;
  firstName: string;
  lastName: string;
  email: string;
  password1: string;
  password2: string;
  corporationId: string;
  corporation: ListCorporationItem;
  type: USER_TYPE;
};

const inputs = [
  { type: "manual", name: "password1", message: "Passwords do not match" },
  { type: "manual", name: "password2", message: "Passwords do not match" },
];

const PAGE_SIZE = 20;

const qKey = "management-list-of-users";

const modelParams = (paginationModel: any, search: string) => {
  const params = {
    page: paginationModel.page + 1,
    page_size: paginationModel.pageSize,
    ordering: "-date_joined" as const,
    hr: "t",
  } as ListUserParams;
  if (search.length > 0) params.search = search;
  return params;
};

const useUserManagement = () => {
  const queryClient = useQueryClient();
  const { setErrorList, setSuccessList } = useCustomSnackBar();
  const methods = useForm<UserMngmntSubmitType>();

  const [registerError, setRegisterError] = React.useState<AxiosError | null>(null);
  const [updateError, setUpdateError] = React.useState<AxiosError | null>(null);
  const [openRegistrationModal, setOpenRegistrationModal] = React.useState(false);
  const [openEditModal, setOpenEditModal] = React.useState(false);
  const [selectedUser, setSelectedUser] = React.useState<any>(null);
  const [paginationModel, setPaginationModel] = React.useState({ page: 0, pageSize: PAGE_SIZE });
  const [allData, setAllData] = React.useState<ListUserItem[]>([]);
  const [search, setSearch] = React.useState("");
  const [showDeleteConfirm, setShowDeleteConfirm] = React.useState(false);
  const [userToDelete, setUserToDelete] = React.useState<{ id: string; username: string } | null>(null);

  const registerMutation = useMutation<any, AxiosError, any, any>({
    mutationFn: registration,
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: [qKey] });
      setSuccessList((pre) => [...pre, "Register succeeded"]);
      setOpenRegistrationModal(false);
    },
    onError: (v) => {
      setRegisterError(v);
    },
  });

  const onSubmit: SubmitHandler<UserMngmntSubmitType> = (data) => {
    if (data.password1 !== data.password2) {
      inputs.forEach(({ name, type, message }) => {
        methods.setError(name as any, { type, message });
      });
      return;
    }
    registerMutation.mutate({
      username: data.name,
      email: data.email,
      first_name: data.firstName,
      last_name: data.lastName,
      corporation: data.corporationId ?? null,
      password: data.password1,
      type: data.type,
    });
  };

  const listQuery = useQuery(
    [qKey, paginationModel, search],
    () => listUsers(modelParams(paginationModel, search)),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      onSuccess: (newData) => {
        if (paginationModel.page === 0) {
          setAllData(newData.data);
        } else {
          setAllData(prev => [...prev, ...newData.data]);
        }
      }
    }
  );

  const onNameChange = React.useMemo(
    () =>
      debounce(({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
        setPaginationModel({ page: 0, pageSize: PAGE_SIZE });
        setSearch(value);
      }, 600),
    []
  );

  const processedData = React.useMemo(() => {
    return allData.map((val) => ({
      ...val,
      name: `${val.first_name} ${val.last_name}`,
      joined: _.isNumber(val.date_joined) 
        ? new Date((val.date_joined as number) * 1000).toLocaleString()
        : val.date_joined
    }));
  }, [allData]);

  const loadMore = React.useCallback(() => {
    if (!listQuery.isFetching && allData.length < (listQuery.data?.meta.count ?? 0)) {
      setPaginationModel(prev => ({
        ...prev,
        page: prev.page + 1
      }));
    }
  }, [listQuery.isFetching, allData.length, listQuery.data?.meta.count]);

  const [searchTerm, setSearchTerm] = React.useState("");

  const {
    data: corporationsPages,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: ["corporationList", searchTerm] as const,
    queryFn: ({ pageParam = 1 }) => listCorporations({
      page: pageParam,
      page_size: PAGE_SIZE,
      search: searchTerm,
    }),
    getNextPageParam: (lastPage) => {
      if (!lastPage?.meta?.count || !lastPage?.data?.length) return undefined;
      return lastPage.meta.count > lastPage.data.length 
        ? Math.ceil(lastPage.data.length / PAGE_SIZE) + 1 
        : undefined;
    },
  });

  const corporations = React.useMemo(() => 
    corporationsPages?.pages.flatMap(page => page.data) ?? [],
    [corporationsPages]
  );

  const updateMutation = useMutation<any, AxiosError, any, any>({
    mutationFn: updateUser,
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: [qKey] });
      setOpenEditModal(false);
    }, 
    onError: (v) => {
      setUpdateError(v);
    },
  });

  const onUpdateSubmit: SubmitHandler<Omit<UserMngmntSubmitType, 'password1' | 'password2'>> = (data) => {
    updateMutation.mutate({
      id: data.id,
      params: {
        username: data.name,
        email: data.email,
        first_name: data.firstName,
        last_name: data.lastName,
        corporation: data.corporation?.id ?? null,
        type: data.type,
      }
    });
  };

  const handleOpenRegistrationModal = () => setOpenRegistrationModal(true);

  const handleCloseRegistrationModal = () => {
    setOpenRegistrationModal(false);
    methods.reset();
    setRegisterError(null);
  };

  const handleEditUser = (user: any) => {
    setSelectedUser(user);
    setOpenEditModal(true);

    methods.reset({
      id: user.id,
      name: user.username,
      firstName: user.first_name,
      lastName: user.last_name,
      email: user.email,
      type: user.type,
      corporation: {
        id: user.corporation_id,
        name: user.corporations,
      }
    });
  };

  const handleCloseEditModal = () => {
    setOpenEditModal(false);
    setSelectedUser(null);
    methods.reset();
    setUpdateError(null);
  };

  const deleteMutation = useMutation<any, AxiosError, string>({
    mutationFn: deleteUser,
    onSuccess: (_, deletedUserId) => {
      setAllData(prevData => prevData.filter(user => user.id !== deletedUserId));
      
      queryClient.refetchQueries({ queryKey: [qKey] });
      setSuccessList((pre) => [...pre, "User deleted successfully"]);
      setShowDeleteConfirm(false);
      setUserToDelete(null);
    },
    onError: (error) => {
      setErrorList((pre) => [...pre, "Failed to delete user"]);
      setShowDeleteConfirm(false);
      setUserToDelete(null);
    },
  });

  const handleDeleteClick = (userId: string, username: string) => {
    setUserToDelete({ id: userId, username });
    setShowDeleteConfirm(true);
  };

  const handleConfirmDelete = () => {
    if (userToDelete) {
      deleteMutation.mutate(userToDelete.id);
    }
  };

  const handleCancelDelete = () => {
    setShowDeleteConfirm(false);
    setUserToDelete(null);
  };

  return {
    methods,
    onSubmit,
    onNameChange,
    listQuery,
    processedData,
    loadMore,
    totalCount: listQuery.data?.meta.count ?? 0,
    corporations,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    setSearchTerm,
    updateMutation,
    onUpdateSubmit,
    openRegistrationModal,
    openEditModal,
    handleOpenRegistrationModal,
    handleCloseRegistrationModal,
    handleEditUser,
    handleCloseEditModal,
    registerError,
    updateError,
    showDeleteConfirm,
    handleDeleteClick,
    handleConfirmDelete,
    handleCancelDelete,
    isDeleting: deleteMutation.isLoading,
    userToDelete,
  };
};

export default useUserManagement;