import React from "react";
import { Box, Button, Card, Checkbox, List, ListItem, Tooltip } from "@mui/material";
import readFromClipboard from "utils/commons";
import AddIcon from "@mui/icons-material/Add";
import AutoCompleteWithFilter from "components/AutoCompleteWithFilter";
import { listDevices, listDevicesV2 } from "queries/devices";
import { useMutation, useQuery } from "@tanstack/react-query";
import CardWithTitle from "components/CardWithTitle";
import { archiveDevices } from "queries/devices/archive";
import useCustomSnackBar from "hooks/useCustomSnackBar";

const ArchiveManagement = () => {
  const { jsx: theSnack, setErrorList, setSuccessList } = useCustomSnackBar();

  const [deviceList, setDeviceList] = React.useState<string[]>([]);
  const [smappIdFromClipboard, setSmappIdFromClipboard] = React.useState<string[]>([]);

  const [keepOldCorporations, setKeepOldCorporations] = React.useState(false);
  const [keepOldUser, setKeepOldUser] = React.useState(false);

  const listDevicesQuery = useQuery(
    ["archive-list-devices"],
    () =>
      listDevicesV2({
        smapp_id__in: smappIdFromClipboard,
        paginator: "off",
        page: 0,
        page_size: 0,
      }),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: false,
      enabled: smappIdFromClipboard.length > 0,
    }
  );

  React.useEffect(() => {
    const devFromClipb = (listDevicesQuery.data || []).map((v) => v.smapp_id);
    if (devFromClipb.length > 0) {
      setDeviceList((pre) => devFromClipb.filter((v) => !pre.includes(v)));
      setSmappIdFromClipboard([]);
    }
  }, [listDevicesQuery.data]);

  const archiveDeviceMutation = useMutation({
    mutationFn: archiveDevices,
    onSuccess: () => {
      setSuccessList((pre) => [...pre, "Archiving succeeded"]);
    },
    onError: () => {
      setErrorList((pre) => [...pre, "Archiving failed"]);
    },
    onSettled: () => {
      setKeepOldCorporations(false);
    },
  });

  return (
    <CardWithTitle
      title="Archive devices"
      sx={{
        display: "flex",
        flexFlow: "column",
        gap: 2,
        p: 1,
        m: 1,
        alignItems: "center",
        flex: 1,
        borderRadius: 6,
        "& .MuiTypography-root": { 
          mt: 1,
          ml: 1,
          variant: "body2",
          fontWeight: "bold"
        }
      }}
    >
      {theSnack}
      <Box
        className="selectDevice"
        sx={{
          mt: 1,
          display: "flex",
          flexFlow: "column",
          gap: 1,
        }}
      >
        <Box sx={{ width: "100%", display: "flex", flexFlow: "row", gap: 1, alignItems: "center" }}>
          <Tooltip title="Add Devices From Clipboard">
            <Button
              sx={{ minWidth: 0, minHeight: 0, width: 0, height: 0.5 }}
              size="small"
              variant="contained"
              onClick={async () => {
                const clipList = await readFromClipboard();
                setSmappIdFromClipboard(clipList);
              }}
            >
              <AddIcon fontSize="small" />
            </Button>
          </Tooltip>
          <AutoCompleteWithFilter
            boxSx={{ width: "100%" }}
            key={deviceList.length} // cuz of this, it will clear input field
            label="Devices"
            qKey="archive-devices"
            qFn={listDevices}
            queryParamsKey={["smapp_id"]}
            extraQueryParams={{ active: true }}
            keyToLabelList={["smapp_id"]}
            lookup="icontains"
            size="small"
            fullWidth
            onChange={(e, value) => {
              if (value) {
                setDeviceList((pre) => [...new Set([...pre, value.label])]);
              }
            }}
          />
        </Box>
        <Card
          sx={{
            height: "200px",
            width: "250px",
            overflowY: "scroll",
            paddingLeft: 2,
            paddingRight: 2,
          }}
        >
          <List>
            {deviceList.map((v) => {
              return (
                <ListItem
                  onClick={() => setDeviceList((pre) => pre.filter((vvv) => vvv !== v))}
                  sx={{
                    borderRadius: "5px",
                    p: 0.125,
                    pl: 0.5,
                    fontSize: "small",
                    m: 0.5,
                  }}
                  key={v}
                >
                  {v}
                </ListItem>
              );
            })}
          </List>
        </Card>
        <Box sx={{ display: "flex", flexFlow: "row", gap: 1 }}>
          <Tooltip title="Keep old corporatoins">
            <Checkbox
              size="small"
              checked={keepOldCorporations}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setKeepOldCorporations(event.target.checked);
              }}
            />
          </Tooltip>
          <Tooltip title="Keep old user">
            <Checkbox
              size="small"
              checked={keepOldUser}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setKeepOldUser(event.target.checked);
              }}
            />
          </Tooltip>
          <Tooltip title="Save changes">
            <Button
              style={{ textTransform: "none" }}
              size="small"
              variant="contained"
              onClick={() => {
                archiveDeviceMutation.mutate({
                  devices_to_archive: deviceList,
                  keep_old_corporation: keepOldCorporations,
                  keep_old_user: keepOldUser,
                });
              }}
            >
              Archive devices
            </Button>
          </Tooltip>
        </Box>
      </Box>
    </CardWithTitle>
  );
};

export default ArchiveManagement;
