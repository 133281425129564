import React from "react";
import { Box, Container, Typography } from "@mui/material";
import DeviceManagement from "./device";
import SkippableDevices from "./skippableDevices";
import ArchiveManagement from "./archive";

const SmappManagamentMain = () => {
  return (
    <Container
      className="SmappManagamentMain"
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 5,
        justifyContent: "center",
        width: "100%",
        maxWidth: "100% !important",
        p: 0,
        m: 0,
      }}
    >
      <Typography variant="h4">Device Management</Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 5,
          justifyContent: "center",
        }}
      >
        <ArchiveManagement />
        <SkippableDevices />
      </Box>
      <DeviceManagement />
    </Container>
  );
};

export default SmappManagamentMain;
