import React from 'react';
import { Drawer, Box, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import { USER_TYPE } from 'queries/users';
import { useNavigate } from 'react-router-dom';
import ROUTES from 'routes';
import useVerticalMenuBar from './useVerticalMenuBar';
import logo from '../../icons/logo.svg';
import logoHWhite from '../../icons/logo-h-white.svg';
import { getMenuItems } from './constants/menuItem';
import AllMenuItems from './AllMenuItems';


const drawerWidth = 240;

const MiniDrawer = styled(Drawer)(({ theme }) => ({
  width: theme.spacing(9),
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  '& .MuiDrawer-paper': {
    backgroundColor: '#27382C',
    borderRadius: '24px',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
    [theme.breakpoints.up('sm')]: {
      width: `calc(${theme.spacing(9)} + 2px)`, // Increase width for larger screens
    },
    '&:hover': {
      width: `calc(${drawerWidth}px + 2px)`, // Increase hover width
      '& .logo-compact': {
        display: 'none',
      },
      '& .logo-expanded': {
        display: 'block',
      },
    },
    margin: '8px',
    height: 'calc(100% - 16px)',
    position: 'fixed',
  },
  '& .MuiListItemIcon-root': {
    color: 'white',
  },
  '& .MuiListItemText-root': {
    color: 'white',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  '& .MuiListItemButton-root': {
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.08)',
    },
  },
  [theme.breakpoints.down('sm')]: {
    '& .MuiDrawer-paper': {
      width: drawerWidth,
      transform: 'translateX(-100%)',
      transition: theme.transitions.create('transform', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    '&.open .MuiDrawer-paper': {
      transform: 'translateX(0)',
    },
  },
}));

const LogoWrapper = styled(Box)(({ theme }) => ({
  padding: '16px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '64px',
  flexShrink: 0,
  '& img': {
    height: 'auto',
  },
  '& .logo-compact': {
    maxWidth: '86%',
    maxHeight: '86%',
  },
  '& .logo-expanded': {
    width: '155px',
    minWidth: '155px',
    maxWidth: '155px',
  },
  [theme.breakpoints.up('sm')]: {
    '& .logo-expanded': {
      display: 'none',
    },
  },
  [theme.breakpoints.down('sm')]: {
    '& .logo-compact': {
      display: 'none',
    },
  },
}));
    
interface VerticalMenuBarProps {
  userType: USER_TYPE;
}

const VerticalMenuBar: React.FC<VerticalMenuBarProps> = ({ userType }) => {
  const navigate = useNavigate();
  const {
    openSubMenu,
    isMenuOpen,
    isSmallScreen,
    handleSubMenuToggle,
    handleItemClick,
    toggleMenu,
    handleMouseLeave,
    handleMouseEnter,
    selectedItem,
    selectedSubItem,
  } = useVerticalMenuBar(userType);

  const FloatingMenuButton = styled(IconButton)(({ theme }) => ({
    position: 'fixed',
    top: 16,
    left: 16,
    backgroundColor: '#27382C',
    width: '46px',
    height: '46px',
    zIndex: 1200,
    color: 'white',
    borderRadius: '16px',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    }
  }));

  const handleLogoClick = () => {
    navigate(ROUTES.HOME);
  };

  return (
    <>
      <MiniDrawer 
        variant={isSmallScreen ? "temporary" : "permanent"}
        open={isSmallScreen ? isMenuOpen : true}
        onClose={toggleMenu}
        className={isMenuOpen ? 'open' : ''}
        onMouseLeave={handleMouseLeave}
        onMouseEnter={handleMouseEnter}
        ModalProps={{
          BackdropProps: { style: { backgroundColor: 'transparent' } },
        }}
      >
        <LogoWrapper onClick={handleLogoClick} sx={{ cursor: 'pointer' }}>
          {isSmallScreen ? (
            <img src={logoHWhite} alt="Logo" />
          ) : (
            <>
              <img src={logo} alt="Logo" className="logo-compact" />
              <img src={logoHWhite} alt="Logo" className="logo-expanded" />
            </>
          )}
        </LogoWrapper>
        <AllMenuItems
          items={getMenuItems(userType)}  // Use the utility function here
          openSubMenu={openSubMenu}
          handleSubMenuToggle={handleSubMenuToggle}
          handleItemClick={handleItemClick}
          selectedItem={selectedItem}
          selectedSubItem={selectedSubItem}
        />
      </MiniDrawer>
      {isSmallScreen && (
        <FloatingMenuButton onClick={toggleMenu} aria-label="menu">
          <MenuIcon />
        </FloatingMenuButton>
      )}
    </>
  );
};

export default VerticalMenuBar;
