import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormControl,
  Stack,
  Alert,
  CircularProgress,
  Box,
  Typography,
  IconButton,
  Divider,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import CloseIcon from '@mui/icons-material/Close';
import { ListCorporationItem } from 'queries/corporations';
import DeviceDetailsTable, { Device } from "../../../components/DeviceManagementTable/DeviceDetailsTable";
import useOrgsDeviceManagement from './useOrgsDeviceManagement';

interface ManageOrganisationModalProps {
  open: boolean;
  onClose: () => void;
  onSave: (data: { name: string; parentId: string | null }) => void;
  error?: string | null;
  currentCorporation?: ListCorporationItem;
  isLoadingParentCorporation: boolean;
  parentCorporation: ListCorporationItem | null;
  setParentSearchTerm: (term: string) => void;
  parentCorporations: ListCorporationItem[];
  handleParentCorporationScroll: (event: React.SyntheticEvent) => void;
  isFetchingNextParentPage: boolean;
  isFetchingParentCorporations: boolean;
  isUpdateSuccess: boolean;
}

const ManageOrganisationModal: React.FC<ManageOrganisationModalProps> = ({
  open,
  onClose,
  onSave,
  error,
  currentCorporation,
  parentCorporation,
  setParentSearchTerm,
  parentCorporations,
  handleParentCorporationScroll,
  isFetchingNextParentPage,
  isFetchingParentCorporations,
  isLoadingParentCorporation,
  isUpdateSuccess,
}) => {
  const [corporationName, setCorporationName] = React.useState(currentCorporation?.name || '');
  const [parentCorp, setParentCorporation] = React.useState<ListCorporationItem | null>(null);

  const { fetchAllDevices, fetchOrgDevices, updateAssignedDevicesForOrg, isUpdating } = useOrgsDeviceManagement(currentCorporation?.id || '');

  const hasChanges = (): boolean => {
    const nameChanged = corporationName !== currentCorporation?.name;
    const parentChanged =
      (parentCorp?.id || null) !== (currentCorporation?.parent_id || null);
    return nameChanged || parentChanged;
  };

  React.useEffect(() => {
    if (currentCorporation) {
      setCorporationName(currentCorporation.name);
      setParentCorporation(parentCorporation || null);
    }
  }, [currentCorporation, parentCorporation]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
      sx={{
        '& .MuiDialog-paper': {
          width: '90%',
          maxWidth: 'none',
          backgroundColor: 'white',
          borderRadius: '24px',
          boxShadow: 2
        }
      }}
    >
      <DialogTitle>
        <Box component="div" sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography component="div" variant="subtitle1" sx={{ fontWeight: 'bold', color: '#27382C' }}>
            Organization settings
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent>
        <Stack
          spacing={0}
          sx={{
            mt: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          <Typography
            variant="body1"
            sx={{ fontWeight: 'bold', color: '#27382C' }}
          >
            General information
          </Typography>
          <Typography
            variant="caption"
            sx={{ marginBottom: 4 }}
          >
            Update the organization details here.
          </Typography>

          <Box sx={{ flex: 1, minWidth: 200, maxWidth: 260 }}>
            <TextField
              fullWidth
              label="Organization Name"
              value={corporationName}
              onChange={(e) => setCorporationName(e.target.value)}
              required
            />

            <FormControl fullWidth sx={{ mt: 2 }}>
              <Autocomplete
                value={parentCorp}
                onChange={(_, newValue) => setParentCorporation(newValue || null)}
                onInputChange={(_, value) => setParentSearchTerm(value)}
                options={parentCorporations}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                noOptionsText={
                  isFetchingParentCorporations ? (
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', py: 1 }}>
                      <CircularProgress size={20} />
                    </Box>
                  ) : 'No options found'
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Parent Organization"
                    placeholder="Select parent organization"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {isLoadingParentCorporation && (
                            <CircularProgress size={20} />
                          )}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
                ListboxProps={{
                  onScroll: handleParentCorporationScroll,
                  style: { maxHeight: 300 }
                }}
                loading={isFetchingNextParentPage || isLoadingParentCorporation}
              />
            </FormControl>

            <Button
              variant="contained"
              onClick={() => onSave({
                name: corporationName,
                parentId: parentCorp?.id || null
              })}
              disabled={!corporationName || !hasChanges() || isLoadingParentCorporation}
              fullWidth
              sx={{ mt: 2, textTransform: "none" }}
            >
              Save changes
            </Button>

            {error && (
              <Alert severity="error" sx={{ mt: 2, borderRadius: 2 }}>
                Oops! Something went wrong.
              </Alert>
            )}

            {isUpdateSuccess && (
              <Alert severity="success" sx={{ mt: 2, borderRadius: 2 }}>
                Success!
              </Alert>
            )}
          </Box>

          <Divider sx={{ width: '100%', my: 4 }} />

          <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
            <Typography
              variant="body1"
              sx={{ fontWeight: 'bold', color: '#27382C' }}
            >
              Device management
            </Typography>
            <Typography
              variant="caption"
              sx={{ marginBottom: 2 }}
            >
              You can update the devices belonging to this organization here.
            </Typography>

            {isUpdating && (
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: 'rgba(255, 255, 255, 0.7)',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  zIndex: 1000,
                }}
              >
                <CircularProgress />
              </Box>
            )}

            <Box sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              gap: 2,
              width: '100%'
            }}>
              <Box sx={{ flex: 1 }}>
                <DeviceDetailsTable
                  isAssignedView={false}
                  updateAssignedStatusForDevices={updateAssignedDevicesForOrg}
                  fetchDevices={fetchAllDevices}
                />
              </Box>

              <Box sx={{ flex: 1 }}>
                <DeviceDetailsTable
                  isAssignedView
                  updateAssignedStatusForDevices={updateAssignedDevicesForOrg}
                  fetchDevices={fetchOrgDevices}
                />
              </Box>
            </Box>
          </Box>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default ManageOrganisationModal; 