import { useState, useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { listDevices } from 'queries/devices';
import { Device } from 'components/DeviceManagementTable/DeviceDetailsTable';
import { updateCorporation, getCorporationDevicesById } from 'queries/corporations';

const useOrgsDeviceManagement = (id: string) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isUpdating, setIsUpdating] = useState(false);

  const queryClient = useQueryClient();

  const updateAssignedDevicesForOrg = useCallback(async (deviceIds: string[], assigned: boolean) => {
    if (!id) {
      throw new Error("Organisation not loaded yet");
    }
    setIsUpdating(true);
    try {
      if (assigned) {
        await updateCorporation({ id, params: { devices_to_add: deviceIds } });
      } else {
        await updateCorporation({ id, params: { devices_to_remove: deviceIds } });
      }

      await queryClient.invalidateQueries({ queryKey: ['devices'] });
      await queryClient.invalidateQueries({ queryKey: ['assignedDevices'] });
      queryClient.invalidateQueries({ queryKey: ['corporations'] });
    } catch (err) {
      console.error('Error updating device assignment:', err);
      throw err;
    } finally {
      setIsUpdating(false);
    }
  }, [id, queryClient]);

  const fetchAllDevices = useCallback(async (page: number, search: string) => {
    if (!id) {
      return { devices: [], nextPage: null };
    }
    try {
      const response = await listDevices({ 
        page, 
        page_size: 10, 
        search,
        corporation_isnull: true,
        active: true,
      });

      const devices: Device[] = response.data.map(device => ({
        id: device.id,
        smappId: device.smapp_id,
        cropName: device.crop_name,
        corporationName: device.corporation?.name || null,
        pestType: device.pest_name,
        isAssigned: false,
      }));

      return {
        devices,
        nextPage: response.meta.next ? page + 1 : null,
      };
    } catch (err) {
      console.error('Error fetching devices:', err);
      throw err;
    }
  }, [id]);

  const fetchOrgDevices = useCallback(async (page: number, search: string) => {
    if (!id) {
      return { devices: [], nextPage: null };
    }

    try {
      const response = await getCorporationDevicesById({ 
        id, 
        search,
        page,
        page_size: 10
      });
      
      const devices: Device[] = response.results.map(device => ({
        id: device.id,
        smappId: device.smapp_id,
        cropName: device.crop_name,
        corporationName: device.corporation_name,
        pestType: device.pest_name,
        isAssigned: true, 
      }));


      return {
        devices,
        nextPage: response.next ? page + 1 : null,
      };
    } catch (err) {
      console.error('Error fetching devices:', err);
      throw err;
    }
  }, [id]);

  return { 
    fetchAllDevices,
    fetchOrgDevices,
    isUpdating,
    updateAssignedDevicesForOrg,
  };
};

export default useOrgsDeviceManagement;