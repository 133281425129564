import React, { useState, useCallback } from "react";
import {
  Container, 
  Typography, 
  Table, 
  TableBody, 
  TextField, 
  Button, 
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TableHead,
  TableRow,
  TableCell,
  TableContainer
} from "@mui/material";
import { useInfiniteQuery } from "@tanstack/react-query";
import ValidatorItem from "./ValidatorItem";
import AddValidatorModal from "./Modals/AddValidatorModal";
import ValidatorDevicesManageModal from "./Modals/ValidatorDevicesManageModal";
import { useValidatorManagement } from "./useValidatorManagement";

const ValidatorManagementMain = () => {
  const {
    fetchValidators,
    selectedValidatorId,
    searchTerm,
    openRegModal,
    openDevicesManageModal,
    setSearchTerm,
    handleOpenRegModal,
    handleCloseRegModal,
    handleOpenDevicesManageModal,
    handleCloseDevicesManageModal,
    register,
    errors,
    showPassword,
    isRegistrationInProgress,
    handleClickShowPassword,
    handleSaveAndClose,
    handleSaveAndAssignDevices,
    registerError,
    handleDeleteValidator,
    showDeleteError,
    setShowDeleteError
  } = useValidatorManagement();

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
  } = useInfiniteQuery(
    ['list-validators', searchTerm],
    ({ pageParam = 1 }) => fetchValidators(pageParam, searchTerm),
    {
      getNextPageParam: (lastPage) => lastPage.nextPage,
    }
  );

  const validators = data?.pages.flatMap(page => page.validators) || [];

  const handleScroll = useCallback((event: React.UIEvent<HTMLDivElement>) => {
    const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
    if (scrollHeight - scrollTop <= clientHeight * 1.5 && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage, isFetchingNextPage]);

  return (
    <Container 
      className="ValidatorManagementMain" 
      maxWidth={false} 
      sx={{ px: 4 }}
    >
       <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center', 
        marginBottom: "16px" 
      }}>
        <Typography variant="h4">
          Validator Management
        </Typography>
        <Button variant="contained" color="primary" onClick={handleOpenRegModal}>
          Add Validator
        </Button>
      </Box>

      <TextField
        variant="outlined"
        label="Find validator"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        sx={{
          marginBottom: "16px",
          width: "40%",
          '& .MuiOutlinedInput-root': {
            borderRadius: "10px",
          },
        }}
      />

      <Box sx={{ 
        borderRadius: '24px', 
        overflow: 'hidden',
        boxShadow: '1',
        bgcolor: 'transparent'
      }}>
        {/* Validators header */}
        <Box sx={{ 
          height: 68, 
          backgroundColor: "#FFFFFF",
          display: 'flex',
          alignItems: 'center',
          pl: 6
        }}>
          <Typography 
            variant="body1"
            fontWeight="bold"
            fontSize="20px"
            sx={{ color: "#27382C" }}
          >
            Validators
          </Typography>
        </Box>

        {/* Table with Headers and Content */}
        <TableContainer 
          sx={{ 
            maxHeight: '70vh', 
            overflow: 'auto', 
            backgroundColor: 'white',
            px: 4
          }}
          onScroll={handleScroll}
        >
          <Table stickyHeader sx={{
            '& .MuiTableHead-root .MuiTableCell-root': {
              backgroundColor: 'white',
            }
          }}>
            <TableHead>
              <TableRow sx={{
                height: 60,
              }}>
                <TableCell sx={{ pl: 3 }}>
                  <Typography variant="caption" fontSize="14px" fontWeight="bold" sx={{ color: "#D4D7D5" }}>
                    NAME
                  </Typography>
                </TableCell>
                <TableCell sx={{ pl: 4 }}>
                  <Typography variant="caption" fontSize="14px" fontWeight="bold" sx={{ color: "#D4D7D5" }}>
                    NUMBER OF DEVICES
                  </Typography>
                </TableCell>
                <TableCell align="right" sx={{ pr: 4 }} >
                  <Typography variant="caption" fontSize="14px" fontWeight="bold" sx={{ color: "#D4D7D5" }}>
                    ACTIONS
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{ bgcolor: 'white' }}>
              {validators.map((validator) => (
                <ValidatorItem
                  key={validator.uuid}
                  name={validator.name}
                  numberOfDevices={validator.numberOfDevices}
                  handleDeleteClick={() => handleDeleteValidator(validator.uuid)}
                  handleAssignDevicesClick={() => handleOpenDevicesManageModal(validator.uuid)}
                />
              ))}
            </TableBody>
          </Table>
          {(isLoading || isFetchingNextPage) && (
            <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
              <CircularProgress />
            </Box>
          )}
        </TableContainer>
      </Box>

      {/* Validator registration */}
      <AddValidatorModal
        open={openRegModal}
        onClose={handleCloseRegModal}
        register={register}
        errors={errors}
        showPassword={showPassword}
        isLoading={isRegistrationInProgress}
        registerError={registerError}
        handleClickShowPassword={handleClickShowPassword}
        handleSaveAndClose={handleSaveAndClose}
        handleAssignDevices={handleSaveAndAssignDevices}
      />

      {/* Validator deletion error */}
      {showDeleteError && (
        <Dialog 
        open 
        onClose={() => setShowDeleteError(false)} 
        PaperProps={{
          style: {
            borderRadius: '16px',
            overflow: 'hidden',
          }
        }}
        >
          <DialogTitle sx={{ color: "#AB0B0B", fontWeight: "bold" }}>Oh snap! :(</DialogTitle>
          <DialogContent>
            <DialogContentText sx={{ color: "#AB0B0B" }}>
              Something went wrong while deleting validator. 
              <br /> Please try again later.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button 
            variant="contained" 
            sx={{ 
              backgroundColor: "#AB0B0B", 
              color: "white", 
              marginRight: "16px", 
              "&:hover": { backgroundColor: "#FBC1C1" } 
            }} 
            onClick={() => setShowDeleteError(false)}>OK</Button>
          </DialogActions>
        </Dialog>
      )}

      {/* Validator devices management */}
      <ValidatorDevicesManageModal
        open={openDevicesManageModal}
        onClose={handleCloseDevicesManageModal}
        validatorId={selectedValidatorId}
      />

    </Container>
  );
}

export default ValidatorManagementMain;
