import { useState, useEffect, useCallback } from 'react';
import { detailUser } from "queries/users";
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { listDevices, updateDevice } from 'queries/devices';
import { Device } from 'components/DeviceManagementTable/DeviceDetailsTable';

export interface Validator {
  id: string;
  name: string;
}

export const useValidatorWithId = (id: string) => {
  const [validator, setValidator] = useState<Validator | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isUpdating, setIsUpdating] = useState(false);

  const userQuery = useQuery({
    queryKey: ["detail-user", { id }],
    queryFn: () => detailUser({ id: id as string }),
    enabled: !!id,
  });

  const queryClient = useQueryClient();

  useEffect(() => {
    if (userQuery.data) {
      setValidator({
        id,
        name: `${userQuery.data.first_name} ${userQuery.data.last_name}`
      });
      setLoading(false);
    } else if (userQuery.error) {
      setError('Failed to fetch validator');
      setLoading(false);
    }
  }, [id, userQuery.data, userQuery.error]);

  const updateAssignedValidatorForDevice = useCallback(async (deviceIds: string[], assigned: boolean) => {
    if (!validator) {
      throw new Error("Validator not loaded yet");
    }
    setIsUpdating(true);
    try {
      await Promise.all(deviceIds.map(deviceId => 
        updateDevice({ id: deviceId, params: { validator: assigned ? validator.id : null } }),
      ));
    } catch (err) {
      console.error('Error updating device assignment:', err);
      throw err;
    } finally {
      queryClient.invalidateQueries({ queryKey: ['devices'] });
      queryClient.invalidateQueries({ queryKey: ['assignedDevices'] });
      setIsUpdating(false);
    }
  }, [validator, queryClient]);

  const fetchAllDevices = useCallback(async (page: number, search: string) => {
    try {
      const response = await listDevices({ 
        page, 
        page_size: 10, 
        search,
        validator_isnull: true,
        active: true,
      });

      const devices: Device[] = response.data.map(device => ({
        id: device.id,
        smappId: device.smapp_id,
        cropName: device.crop_name,
        corporationName: device.corporation?.name || null,
        pestType: device.pest_name,
        isAssigned: false,
      }));

      return {
        devices,
        nextPage: response.meta.next ? page + 1 : null,
      };
    } catch (err) {
      console.error('Error fetching devices:', err);
      throw err;
    }
  }, []);

  const fetchValidatorDevices = useCallback(async (page: number, search: string) => {
    if (!validator) {
      throw new Error("Validator not loaded yet");
    }

    try {
      const response = await listDevices({ 
        page, 
        page_size: 10, 
        search,
        validator__id: validator.id,
      });

      const devices: Device[] = response.data.map(device => ({
        id: device.id,
        smappId: device.smapp_id,
        cropName: device.crop_name,
        corporationName: device.corporation?.name || null,
        pestType: device.pest_name,
        isAssigned: true, 
      }));

      return {
        devices,
        nextPage: response.meta.next ? page + 1 : null,
      };
    } catch (err) {
      console.error('Error fetching devices:', err);
      throw err;
    }
  }, [validator]);

  return { 
    validator, 
    loading: loading || userQuery.isLoading, 
    error: error || userQuery.error, 
    fetchAllDevices,
    fetchValidatorDevices,
    updateAssignedValidatorForDevice,
    isUpdating
  };
};
