import React from "react";
import { CircularProgress, Typography, Box } from '@mui/material';
import { useValidatorWithId } from './useValidatorWithId';
import DeviceDetailsTable from "../../../../components/DeviceManagementTable/DeviceDetailsTable";

type ValidatorViewWithIdProps = {
  id: string;
};

const ValidatorViewWithId: React.FC<ValidatorViewWithIdProps> = ({ id }) => {
  const {
    validator,
    loading,
    isUpdating,
    error,
    fetchAllDevices,
    fetchValidatorDevices,
    updateAssignedValidatorForDevice
  } = useValidatorWithId(id);

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography color="error">{String(error)}</Typography>;
  }

  if (!validator) {
    return <Typography>No validator found</Typography>;
  }

  return (
    <Box sx={{ position: 'relative' }}>
      {isUpdating && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000,
          }}
        >
          <CircularProgress />
        </Box>
      )}
      <Box>
        <Typography variant="h5" sx={{ fontWeight: 'bold' }}>Manage devices for {validator.name}</Typography>
      </Box>

      <Box sx={{ 
        display: "flex", 
        gap: 2, 
        flexFlow: "row", 
        width: "100%", 
        marginTop: 2 ,
        padding: "20px"
      }}>
        <Box sx={{ flex: 1 }}>
          <DeviceDetailsTable
            isAssignedView={false}
            updateAssignedStatusForDevices={updateAssignedValidatorForDevice}
            fetchDevices={fetchAllDevices}
          />
        </Box>

        <Box sx={{ flex: 1 }}>
          <DeviceDetailsTable
            isAssignedView
            updateAssignedStatusForDevices={updateAssignedValidatorForDevice}
            fetchDevices={fetchValidatorDevices}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default ValidatorViewWithId;
