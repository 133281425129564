import React from "react";
import { ThemeProvider as MUIThemeProvider, createTheme } from "@mui/material/styles";
import { useUserPreferences } from "./UserPreferencesProvider";

const ThemeProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { directThemeMode } = useUserPreferences();


  const theme = createTheme({
    palette: {
      primary: {
        light: "#F4B71D",
        main: "#F4B71D",
        dark: "#F4B71D",
        contrastText: "#27382C",
      },
      error: {
        main: '#AB0B0B',
        light: '#AB0B0B',
        dark: '#AB0B0B',
        contrastText: '#F1EEEA',
      },
      warning: {
        main: '#DB721F',
        light: '#DB721F',
        dark: '#DB721F',
        contrastText: '#F1EEEA',
      },
      success: {
        main: '#428734',
        light: '#428734',
        dark: '#428734',
        contrastText: '#F1EEEA',
      },
      text: {
        primary: '#27382C',
        secondary: '#606963',
      },
      mode: directThemeMode,
    },
    typography: {
      fontFamily: ['Lexend', 'sans-serif'].join(','),
      h1: {
        fontWeight: 600,
        fontSize: '96px',
      },
      h2: {
        fontWeight: 600,
        fontSize: '60px',
      },
      h3: {
        fontWeight: 600,
        fontSize: '48px',
      },
      h4: {
        fontWeight: 400,
        fontSize: '34px',
      },
      h5: {
        fontWeight: 400,
        fontSize: '24px',
      },
      h6: {
        fontWeight: 400,
        fontSize: '20px',
      },
      body1: {
        fontWeight: 300,
        fontSize: '16px',
      },
      body2: {
        fontWeight: 200,
        fontSize: '14px',
      },
      caption: {
        fontWeight: 300,
        fontSize: '12px',
      },
      button: {
        fontWeight: 400,
        fontSize: '14px',
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: '32px',
            textTransform: 'none',
            boxShadow: 'none'
          },
          contained: {
            '&:hover': {
              backgroundColor: '#FAE2A4',
              boxShadow: 'none',
            },
            '&.Mui-disabled': {
              opacity: 0.5,
              backgroundColor: '#C6BAA9',
              borderColor: 'none',
              color: '#F1EEEA',
            },
          },
          outlined: {
            borderWidth: '1.5px',
            borderColor: '#27382C',
            color: '#27382C',
            '&:hover': {
              borderWidth: '1.5px',
              backgroundColor: '#D4D7D5',
              borderColor: '#27382C',
            },
            '&.Mui-disabled': {
              borderColor: 'rgba(204, 186, 169, 0.5)',
              color: 'rgba(204, 186, 169, 0.5)',
              backgroundColor: 'rgba(204, 186, 169, 0.1)',
            }
          },
          containedError: {
            '&:hover': {
              backgroundColor: 'rgba(211, 47, 47, 0.6)',
            }
          }
        },
      },
    },
  });
  return <MUIThemeProvider theme={theme}>{children}</MUIThemeProvider>;
};

export default ThemeProvider;
