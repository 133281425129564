import React from "react";
import { TableRow, TableCell, IconButton, Checkbox, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

interface DeviceDetailsRowItemProps {
    smappId: string;
    cropName: string;
    corporationName?: string | null;
    pestType: string;
    isAssigned: boolean;
    isSelected: boolean;
    handleAssignClick: () => void;
    handleUnassignClick: () => void;
    setIsSelected: (isSelected: boolean) => void;
}

const DeviceDetailsRowItem: React.FC<DeviceDetailsRowItemProps> = ({
    smappId,
    cropName,
    corporationName,
    pestType,
    isAssigned,
    isSelected,
    handleAssignClick,
    handleUnassignClick,
    setIsSelected
}) => {
    const handleRowClick = (event: React.MouseEvent) => {
        // Prevent row click when clicking on the assign/unassign button
        if (event.target instanceof HTMLButtonElement || event.target instanceof SVGElement) {
            return;
        }
        setIsSelected(!isSelected);
    };

    return (
        <TableRow
            onClick={handleRowClick}
            sx={{
                backgroundColor: isSelected ? '#D4D7D5' : 'rgba(212, 215, 213, 0.3)',
                '& td:first-of-type': {
                    borderTopLeftRadius: '18px',
                    borderBottomLeftRadius: '18px',
                    borderLeft: isSelected ? '1px solid #27382C' : 'none',
                },
                '& td:last-child': {
                    borderTopRightRadius: '18px',
                    borderBottomRightRadius: '18px',
                    borderRight: isSelected ? '1px solid #27382C' : 'none',
                },
                '& td': {
                    borderBottom: isSelected ? '1px solid #27382C' : 'none',
                    borderTop: isSelected ? '1px solid #27382C' : 'none',
                },
                cursor: 'pointer', 
            }}
        >
            <TableCell padding="checkbox">
                <Checkbox
                    checked={isSelected}
                    onChange={() => setIsSelected(!isSelected)}
                    onClick={(event) => event.stopPropagation()} 
                />
            </TableCell>
            <TableCell>
                <Typography variant="caption" sx={{ fontWeight: 'bold', display: 'block' }}>
                    {smappId.toUpperCase()}
                </Typography>
                {corporationName && (
                    <Typography variant="caption" sx={{  fontWeight: 'bold', display: 'block' }}>
                        {corporationName}
                    </Typography>
                )}
                <Typography variant="caption" sx={{ display: 'block' }}>
                    {cropName}
                </Typography>
                <Typography variant="caption" sx={{ display: 'block' }}>
                    {pestType}
                </Typography>
            </TableCell>
            <TableCell align="right">
                {isAssigned ? (
                    <IconButton onClick={handleUnassignClick} size="small" sx={{ color: '#27382C' }}>
                        <CloseIcon />
                    </IconButton>
                ) : (
                    <IconButton onClick={handleAssignClick} size="small" sx={{ color: '#27382C' }}>
                        <AddCircleOutlineIcon />
                    </IconButton>
                )}
            </TableCell>
        </TableRow>
    );
};

export default DeviceDetailsRowItem;
