import * as React from "react";
import { Box, TextField, Tooltip, InputAdornment, Button, Paper, Card, } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  exportDeviceDelta,
  listDevicesDeltaCount,
  ExportDeltaCountItem,
  ListDeviceDeltaCountItem,
} from "queries/devices/deviceExport";
import DatePickers from "pages/export/datePicker";
import Typography from '@mui/material/Typography';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ReportOutlinedIcon from '@mui/icons-material/ReportOutlined';
import { ReactComponent as MothInAngleIcon } from '../../../../icons/moth-in-angle-icon.svg'

const qKey = "listOfDevices";

const listModel = (params: ExportDeltaCountItem) => {
  return listDevicesDeltaCount(params);
};

const DeviceTable = () => {
  const [dateRange, setDateRange] = React.useState({
    fromDate: new Date(new Date().setDate(new Date().getDate() - 90)).toISOString().split("T")[0],
    toDate: new Date().toISOString().split("T")[0],
  });

  const handleDateChange = (date: { fromDate: string; toDate: string }) => {
    setDateRange(date);
  };

  const exportMutation = useMutation({
    mutationFn: (params: ExportDeltaCountItem) => exportDeviceDelta(params),
  });

  const downloadData = async () => {
    const csvFile = await exportMutation.mutateAsync({
      start_date: dateRange.fromDate,
      end_date: dateRange.toDate,
    });
    const csvURL = URL.createObjectURL(csvFile);
    const tempLink = document.createElement("a");
    tempLink.href = csvURL;
    tempLink.setAttribute("download", `devices.${dateRange.fromDate}_to_${dateRange.toDate}.csv`);
    tempLink.click();
  };

  const listQuery = useQuery(
    [qKey, dateRange],
    () => listModel({ preview: true, start_date: dateRange.fromDate, end_date: dateRange.toDate }),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: false,
    }
  );

  const newData = Array.isArray(listQuery?.data)
    ? listQuery.data
        .slice(0, 20)
        .map((item: ListDeviceDeltaCountItem, idx: number) => ({ ...item, itemIndex: idx }))
    : [];

    const table = (
      <Box sx={{ overflow: 'hidden' }}>
        {/* Header */}
        <Box sx={{ 
          height: 68, 
          backgroundColor: "#FFFFFF",
          display: 'flex',
          alignItems: 'center',
          pl: 5,
        }}>
          <Typography 
            variant="body1"
            fontWeight="bold"
            fontSize="18px"
            sx={{ color: "#27382C" }}
          >
            DEVICE DATA PREVIEW
          </Typography>
        </Box>

        {/* Column Labels */}
        <Box sx={{
          display: 'flex',
          height: 60,
          backgroundColor: "#FFFFFF",
          alignItems: 'center',
          borderBottom: '1px solid #D4D7D5',
          mx: 3
        }}>
          <Box sx={{ flex: 1, pl: 2 }}>
            <Typography variant="caption" fontSize="14px" fontWeight="bold" sx={{ color: "#D4D7D5" }}>
              CORPORATION
            </Typography>
          </Box>
          <Box sx={{ flex: 1, height: '100%', display: 'flex', alignItems: 'center', pl: 5 }}>
            <Typography variant="caption" fontSize="14px" fontWeight="bold" sx={{ color: "#D4D7D5" }}>
              DEVICE ID
            </Typography>
          </Box>
          <Box sx={{ flex: 1, height: '100%', display: 'flex', alignItems: 'center', pl: 3 }}>
            <Typography variant="caption" fontSize="14px" fontWeight="bold" sx={{ color: "#D4D7D5" }}>
              COORDINATES
            </Typography>
          </Box>
          <Box sx={{ flex: 1, height: '100%', display: 'flex', alignItems: 'center', pl: 3 }}>
            <Typography variant="caption" fontSize="14px" fontWeight="bold" sx={{ color: "#D4D7D5" }}>
              DATE
            </Typography>
          </Box>
          <Box sx={{ flex: 0.5, height: '100%', display: 'flex', alignItems: 'center', pl: 3 }}>
            <Typography variant="caption" fontSize="14px" fontWeight="bold" sx={{ color: "#D4D7D5" }}>
              CATCH COUNT
            </Typography>
          </Box>
        </Box>

        {/* Table Rows */}
        <Box sx={{ 
          overflowY: 'auto', 
          maxHeight: 'calc(68vh - 128px)'
        }}>
          {newData.map((row) => (
            <Paper
              key={row.itemIndex}
              elevation={0}
              sx={{
                display: 'flex',
                bgcolor: '#FFFFFF',
                height: 100,
                borderBottom: '1px solid #D4D7D5',
                borderRadius: 0,
                mx: 3
              }}
            >
              <Box sx={{ flex: 1, pl: 2, display: 'flex', alignItems: 'center' }}>
                <Typography variant="body1" fontSize="16px">
                  {row.corporate_name}
                </Typography>
              </Box>
              <Box sx={{ flex: 1, pl: 4, display: 'flex', alignItems: 'center' }}>
                <Typography variant="body1" fontSize="16px">
                  {row.smapp_id}
                </Typography>
              </Box>
              <Box sx={{ flex: 1, pl: 2, display: 'flex', alignItems: 'center' }}>
                <Typography variant="body1" fontSize="16px">
                  Lat: {row.latitude}
                  <br />
                  Lon: {row.longitude}
                </Typography>
              </Box>
              <Box sx={{ flex: 1, pl: 2, display: 'flex', alignItems: 'center' }}>
                <Typography variant="body1" fontSize="16px">
                  {new Date(row.date).toLocaleDateString('en-GB', { year: 'numeric', month: 'long', day: 'numeric' }).replace(',', '').replace(' ', '. ')}
                </Typography>
              </Box>
              <Box sx={{ flex: 0.5, pl: 2, display: 'flex', alignItems: 'center' }}>
                <Typography variant="body1" fontSize="16px" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <MothInAngleIcon width="24px" height="24px" />
                  {row.detection_count_delta ? row.detection_count_delta : 0}
                </Typography>
              </Box>
            </Paper>
          ))}
        </Box>
      </Box>
    );
  
    return (
      <Box sx={{ display: "flex", flexFlow: "column", width: "100%", gap: 0.5, height: "100%" }}>
        <Typography 
        variant="h4"
        sx={{
          mb: 4,
        }}
        >
          Export device data
        </Typography>
        <Box 
        sx={{ display: "flex", flexFlow: "row", gap: 1, justifyContent: "space-between", alignItems: "center" }}>
          {/* Left side with search and date pickers */}
          <Box sx={{ display: "flex", flexFlow: "row", gap: 2 }}>
            <Box sx={{ 
              width: '400px',
              '& .MuiTextField-root': {
                width: '190px',
              }
            }}>
              <DatePickers onDateChange={handleDateChange} />
            </Box>
            <TextField
              variant="outlined"
              placeholder="Search"
              sx={{
                width: 400,
                '& .MuiOutlinedInput-root': {
                  borderRadius: 3,
                  height: '56px',
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon sx={{ color: '#A3A3A3' }} />
                  </InputAdornment>
                ),
              }}
            />
          </Box>

          {/* Right aligned button */}
          <Tooltip title="Download all">
            <span>
              <Button
                variant="contained"
                endIcon={<FileDownloadOutlinedIcon />}
                onClick={downloadData}
                disabled={newData.length === 0}
              >
                Download all
              </Button>
            </span>
          </Tooltip>
        </Box>
        <Card sx={{ borderRadius: 6, mt: 4, overflow: 'hidden', bgcolor: '#FFFFFF' }}>
          {table}
        </Card>
        <Box>
          {newData.length === 0 ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <Typography 
              variant="body1"
              fontWeight="bold"
              fontSize="18px"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                color: "#DB721F",
              }}
            >
              <ReportOutlinedIcon fontSize="large" sx={{ mr: 1 }} />
              No data available for the selected date range
            </Typography>
          </Box>
        ) : (
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 2 }}>
            <Typography
              variant="caption"
              fontSize="16px"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                color: "#DB721F",
              }}
            >
              <InfoOutlinedIcon fontSize="medium" sx={{ mr: 1 }} />
              This page displays a preview. Please use &apos;Download All&apos; to retrieve the full dataset.
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default DeviceTable;
